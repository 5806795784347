import { MachineVncStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class VncSelectors {
  public static selectVncUrl = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrl.url;

  public static selectVncExpiresAt = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrl.expiresAt;

  public static selectVncMachineId = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrl.machineId;

  public static selectVncMachineSoftwareVersion = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrl.softwareVersion;

  public static selectVncUrlIsLoading = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrl.isLoading;

  public static selectVncUrlIsCallingDone = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrl.isCallingDone;

  public static selectVncStatus = (state: IState): Optional<MachineVncStatus> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncStatus.status;

  public static selectVncStatusIsLoading = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncStatus.isLoading;

  public static selectVncToken = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrlV2.token;

  public static selectVncRegion = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrlV2.region;

  public static selectVncExpiresAtV2 = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrlV2.expiresAt;

  public static selectVncSessionIdV2 = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrlV2.sessionId;

  public static selectVncMachineIdV2 = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrlV2.machineId;

  public static selectVncMachineSoftwareVersionV2 = (state: IState): Optional<string> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrlV2.softwareVersion;

  public static selectVncUrlIsLoadingV2 = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrlV2.isLoading;

  public static selectVncUrlIsCallingDoneV2 = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncUrlV2.isCallingDone;

  public static selectVncStatusV2 = (state: IState): Optional<MachineVncStatus> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncStatusV2.statusV2;

  public static selectVncStatusV2IsLoading = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncStatusV2.isLoading;

  public static selectVncWindowIsOpen = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncWindow.isOpen;

  public static selectVncWindowIsExpanded = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncWindow.isExpanded;

  public static selectVncWindowIsMinimized = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncWindow.isMinimized;

  public static selectVncWindowIsOpenV2 = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].vnc.vncWindow.isOpenV2;
}
