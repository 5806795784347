import { CallEffect, ForkEffect, GetContextEffect, PutEffect, RaceEffect } from 'redux-saga/effects';
import { SagaGenerator, call, getContext, put, race, retry, take, takeLatest } from 'typed-redux-saga';
import { VncModalActions } from '../modals/state/vncModalSlice';
import * as VncActionsTypes from './vncActions.types';
import { VncActions } from './vncSlice';
import {
  RobotRequestVncResponse,
  RobotRequestVncResponseV2,
} from 'app/modules/machine-inventory/interfaces/Robot.types';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  MachineVncStatus,
  ResponseMachineVncStatusGet,
  ResponseMachineVncStatusGetV2,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';
import { VNC_POLL_MAX_RETRIES, VNC_POLL_INTERVAL, VNC_POLL_MAX_RETRIES_V2 } from 'config/constants';

export function* requestVncUrlSaga(
  action: VncActionsTypes.VncRequestUrlRequestAction
): Generator<
  | RaceEffect<
      | SagaGenerator<Optional<RobotRequestVncResponse>, CallEffect<Optional<RobotRequestVncResponse>>>
      | SagaGenerator<VncActionsTypes.VncRequestUrlCancelAction>
    >
  | PutEffect<VncActionsTypes.VncRequestUrlSuccessAction>
  | PutEffect<VncActionsTypes.VncRequestUrlErrorAction>
  | GetContextEffect,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { response } = yield* race({
      response: call(robotService.requestRobotVnc, { machineId: action.payload.machineId }),
      cancel: take(VncActions.vncRequestUrlCancel),
    });

    if (response) {
      yield* put(VncActions.vncRequestUrlSuccess(response.machineVncRequest));
    }
  } catch (error) {
    console.error(error);

    yield* put(VncActions.vncRequestUrlError({ error }));
  }
}

export function* getVncUrlStatusSaga(
  action: VncActionsTypes.VncGetUrlStatusRequestAction
): Generator<
  | GetContextEffect
  | RaceEffect<
      | SagaGenerator<Optional<ResponseMachineVncStatusGet>, CallEffect<Optional<ResponseMachineVncStatusGet>>>
      | SagaGenerator<VncActionsTypes.VncGetUrlStatusCancelAction>
    >
  | PutEffect<VncActionsTypes.VncGetUrlStatusSuccessAction>
  | PutEffect
  | PutEffect<VncActionsTypes.VncGetUrlStatusErrorAction>,
  void,
  any
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  const getRobotVncStatus = async (): Promise<Optional<ResponseMachineVncStatusGet>> => {
    const robotVncStatusResponse = await robotService.getRobotVncStatus(action.payload);
    const { status } = robotVncStatusResponse?.machineVncStatusGet.data || {};

    if (status === MachineVncStatus.Waiting) {
      throw new Error('VNC is not ready yet!');
    }

    return robotVncStatusResponse?.machineVncStatusGet;
  };

  try {
    const { dataResponse } = yield* race({
      dataResponse: retry(VNC_POLL_MAX_RETRIES, VNC_POLL_INTERVAL, getRobotVncStatus),
      cancel: take(VncActions.vncGetUrlStatusCancel),
    });

    if (dataResponse?.data) {
      yield* put(VncActions.vncGetUrlStatusSuccess(dataResponse));
    }

    if (dataResponse?.data.status === MachineVncStatus.Failed) {
      yield* put(VncModalActions.vncModalStatusShow());
    }
  } catch (error) {
    console.error(error);

    yield* put(VncActions.vncGetUrlStatusError({ error }));
  }
}

export function* requestVncUrlV2Saga(
  action: VncActionsTypes.VncRequestUrlV2RequestAction
): Generator<
  | RaceEffect<
      | SagaGenerator<Optional<RobotRequestVncResponseV2>, CallEffect<Optional<RobotRequestVncResponseV2>>>
      | SagaGenerator<VncActionsTypes.VncRequestUrlV2CancelAction>
    >
  | PutEffect<VncActionsTypes.VncRequestUrlV2SuccessAction>
  | PutEffect<VncActionsTypes.VncRequestUrlV2ErrorAction>
  | GetContextEffect,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { response } = yield* race({
      response: call(robotService.requestRobotVncV2, { machineId: action.payload.machineId }),
      cancel: take(VncActions.vncRequestUrlV2Cancel),
    });

    if (response) {
      yield* put(VncActions.vncRequestUrlV2Success(response.machineVncRequestV2));
    }
  } catch (error) {
    console.error(error);

    yield* put(VncActions.vncRequestUrlV2Error({ error }));
  }
}

export function* getVncUrlStatusV2Saga(
  action: VncActionsTypes.VncGetUrlStatusV2RequestAction
): Generator<
  | GetContextEffect
  | RaceEffect<
      | SagaGenerator<Optional<ResponseMachineVncStatusGetV2>, CallEffect<Optional<ResponseMachineVncStatusGetV2>>>
      | SagaGenerator<VncActionsTypes.VncGetUrlStatusV2CancelAction>
    >
  | PutEffect<VncActionsTypes.VncGetUrlStatusV2SuccessAction>
  | PutEffect
  | PutEffect<VncActionsTypes.VncGetUrlStatusV2ErrorAction>,
  void,
  any
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  const getRobotVncStatusV2 = async (): Promise<Optional<ResponseMachineVncStatusGetV2>> => {
    const robotVncStatusResponseV2 = await robotService.getRobotVncStatusV2(action.payload);
    const { status } = robotVncStatusResponseV2?.machineVncStatusGetV2.data || {};

    if (status === MachineVncStatus.Waiting) {
      throw new Error('VNC is not ready yet!');
    }

    return robotVncStatusResponseV2?.machineVncStatusGetV2;
  };

  try {
    const { dataResponse } = yield* race({
      dataResponse: retry(VNC_POLL_MAX_RETRIES_V2, VNC_POLL_INTERVAL, getRobotVncStatusV2),
      cancel: take(VncActions.vncGetUrlStatusV2Cancel),
    });

    if (dataResponse?.data) {
      yield* put(VncActions.vncGetUrlStatusV2Success(dataResponse));
    }

    if (dataResponse?.data.status === MachineVncStatus.Failed) {
      yield* put(VncModalActions.vncModalStatusShow());
    }
  } catch (error) {
    console.error(error);

    yield* put(VncActions.vncGetUrlStatusV2Error({ error }));
  }
}

export function* vncSaga(): Generator<ForkEffect<never | unknown>, void> {
  yield* takeLatest(VncActions.vncRequestUrlRequest, requestVncUrlSaga);
  yield* takeLatest(VncActions.vncGetUrlStatusRequest, getVncUrlStatusSaga);
  yield* takeLatest(VncActions.vncRequestUrlV2Request, requestVncUrlV2Saga);
  yield* takeLatest(VncActions.vncGetUrlStatusV2Request, getVncUrlStatusV2Saga);
}
