import { createSlice } from '@reduxjs/toolkit';
import {
  MachineDetailsDrawerAction,
  SiteDetailsDrawerAction,
  OperatorDetailsDrawerAction,
} from './drawersActions.types';

export interface DrawersState {
  machineDetails: {
    machineId: string | null;
    visible: boolean;
  };
  siteDetails: {
    siteId: string | null;
    visible: boolean;
  };
  operatorDetails: {
    operatorId: string | null;
    visible: boolean;
  };
  userManagement: {
    visible: boolean;
  };
}

export const initialState: DrawersState = {
  machineDetails: {
    machineId: null,
    visible: false,
  },
  siteDetails: {
    siteId: null,
    visible: false,
  },
  operatorDetails: {
    operatorId: null,
    visible: false,
  },
  userManagement: {
    visible: false,
  },
};

const drawersSlice = createSlice({
  name: 'drawers',
  initialState,
  reducers: {
    showMachineDetailsDrawer: (state, action: MachineDetailsDrawerAction) => {
      state.siteDetails.visible = false;
      state.siteDetails.siteId = null;

      state.machineDetails.machineId = action.payload.machineId;
      state.machineDetails.visible = true;

      return state;
    },
    hideMachineDetailsDrawer: state => {
      state.machineDetails.visible = false;
      state.machineDetails.machineId = null;

      return state;
    },
    showSiteDetailsDrawer: (state, action: SiteDetailsDrawerAction) => {
      state.machineDetails.visible = false;
      state.machineDetails.machineId = null;

      state.siteDetails.siteId = action.payload.siteId;
      state.siteDetails.visible = true;

      return state;
    },
    hideSiteDetailsDrawer: state => {
      state.siteDetails.visible = false;
      state.siteDetails.siteId = null;

      return state;
    },
    showOperatorDetailsDrawer: (state, action: OperatorDetailsDrawerAction) => {
      state.operatorDetails.operatorId = action.payload.operatorId;
      state.operatorDetails.visible = true;

      return state;
    },
    hideOperatorDetailsDrawer: state => {
      state.operatorDetails.visible = false;
      state.operatorDetails.operatorId = null;
      return state;
    },
  },
});

export const DrawersActions = drawersSlice.actions;
export const DrawersReducer = drawersSlice.reducer;
