import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RoutePaths } from '../../../../../config/route-paths';
import { IRoute } from '../../interfaces/Routing.types';
import { selectHasAccessToGCD, selectHasAccessToRobots } from '../../../authentication/state/authenticationSelectors';

export interface RoutingGuardProps {
  route: IRoute;
  children?: React.ReactNode;
}

export const RoutingGuard: React.FC<RoutingGuardProps> = ({ route, children }) => {
  const hasAccessToRobots = useSelector(selectHasAccessToRobots);
  const hasAccessToGCD = useSelector(selectHasAccessToGCD);
  const isRobotDashboardRoute = route.path === RoutePaths.ROBOT_DASHBOARD;
  const isOverviewRoute = route.path === RoutePaths.OVERVIEW;

  if (!hasAccessToRobots && isRobotDashboardRoute) {
    return <Navigate to={RoutePaths.OVERVIEW} />;
  }

  if (hasAccessToRobots && !hasAccessToGCD && isOverviewRoute) {
    return <Navigate to={RoutePaths.ROBOT_DASHBOARD} />;
  }

  return <>{children}</>;
};
