import styled from 'styled-components';

export const StyledDownloadHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 440px 24px 288px;
  font-family: ${(props): string => props.theme.fonts.heading1};
  background-color: ${(props): string => props.theme.colors.brightGrey};

  h2 {
    text-transform: uppercase;
    margin-bottom: 0px;
    ${(props): string => props.theme.fontStyles.redesign.heading1};
  }

  .download-header__download-button {
    padding-top: 12px !important;
  }

  .download-header__download-button--hidden {
    // Dirty hack to hide download button without affecting header layout
    opacity: 0;
    pointer-events: none;
  }

  .download-header__back-button {
    :hover {
      background: unset;
    }
  }
`;
