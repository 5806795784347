import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface SortIconProps {
  sortOrder: SortOrders;
}

export const SortIcon = ({ sortOrder }: SortIconProps): JSX.Element => {
  const ActualSortIcon = sortOrder === SortOrders.Asc ? <CaretUpOutlined /> : <CaretDownOutlined />;

  return <>{ActualSortIcon}</>;
};
