import { useContext } from 'react';
import { isNil } from 'lodash-es';
import { AppConfigurationContext } from '../AppConfigurationContext';
import { AppConfigurationNotDefinedError } from '../errors/AppConfigurationNotDefinedError';
import { IAppConfiguration } from '../interfaces/IAppConfiguration';
import { GoogleMapsApiKeyNotDefinedError } from '../errors/GoogleMapsApiKeyNotDefinedError';
import { isNonEmptyEnvVar } from 'lib/utils/env-variables/isNonEmptyEnvVar';

/**
 * Hook to access to the current app configuration
 *
 * @returns - Current app configuration
 */
export function useAppConfig(): IAppConfiguration {
  const appConfiguration = useContext(AppConfigurationContext);

  if (isNil(appConfiguration)) {
    throw new AppConfigurationNotDefinedError();
  }

  if (!isNonEmptyEnvVar(appConfiguration.googleMaps.apiKey)) {
    throw new GoogleMapsApiKeyNotDefinedError();
  }

  return appConfiguration;
}
