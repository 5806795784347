import { useContext } from 'react';
import { isNil } from 'lodash-es';
import { DependenciesContext } from '../DependenciesContext';
import { DependenciesNotDefinedError } from '../errors/DependenciesNotDefinedError';
import { IDependencies } from '../interfaces/IDependencies';

/**
 * Hook to access services inside React components
 *
 * @returns - dependencies
 */
export function useDependencies(): IDependencies {
  const dependencies = useContext(DependenciesContext);

  if (isNil(dependencies)) {
    throw new DependenciesNotDefinedError();
  }

  return dependencies;
}
