import { useContext } from 'react';
import { isNil } from 'lodash-es';
import { IRoute } from '../interfaces/Routing.types';
import { RoutesContext } from '../RoutesContext';
import { RoutesNotDefinedError } from '../errors/RoutesNotDefinedError';

/**
 * Hook to access route definitions
 *
 * @returns - routes
 */
export function useRoutes(): IRoute[] {
  const routes = useContext(RoutesContext);

  if (isNil(routes)) {
    throw new RoutesNotDefinedError();
  }

  return routes;
}
