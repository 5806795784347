import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Button, Drawer, Spin } from 'antd';
import { VncScreen } from '@kaercher/react-vnc';
import { VncExpirationModal } from '../modals/components/VncExpirationModal/VncExpirationModal';
import { VncStatusModal } from '../modals/components/VncStatusModal/VncStatusModal';
import { VncModalSelectors } from '../modals/state/vncModalSelectors';
import { VncSelectors } from '../state/vncSelectors';
import { VncActions } from '../state/vncSlice';
import { VncModalActions } from '../modals/state/vncModalSlice';
import { VncUtils } from '../util/VncUtils';
import { WS_PROTOCOLS } from '../util/constant';
import { StyledVncV2 } from './VncV2.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import vncPlaceholder from 'lib/assets/images/icons/Remote_Control_Placeholder.png';
import { MachineVncStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  LOCAL_STORAGE_IS_USING_VNC_V2_KEY,
  LOCAL_STORAGE_VNC_KEY,
  LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY,
  LOCAL_STORAGE_VNC_SERIAL_NUMBER,
} from 'config/constants';

export const VncV2 = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nowIntervalRef = useRef<any>(null);
  const nowRef = useRef(new Date().toISOString());
  const machineIdLocalStored = localStorage.getItem(LOCAL_STORAGE_VNC_KEY);
  const serialNumber = localStorage.getItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER);
  const machineSoftwareVersionLocalStored = localStorage.getItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);

  const vncToken = useSelector(VncSelectors.selectVncToken) || '';
  const vncRegion = useSelector(VncSelectors.selectVncRegion) || '';
  const vncExpiresAt = useSelector(VncSelectors.selectVncExpiresAtV2) || '';
  const vncSessionId = useSelector(VncSelectors.selectVncSessionIdV2) || '';

  const isRequestingVncUrlLoading = !!useSelector(VncSelectors.selectVncUrlIsLoadingV2);
  const machineIdSelector = useSelector(VncSelectors.selectVncMachineIdV2) || '';
  const isRequestingVncUrlDone = !!useSelector(VncSelectors.selectVncUrlIsCallingDoneV2);
  const vncUrlStatusV2 = useSelector(VncSelectors.selectVncStatusV2);
  const isGettingVncStatusV2Loading = !!useSelector(VncSelectors.selectVncStatusV2IsLoading);

  const isVncWindowOpen = !!useSelector(VncSelectors.selectVncWindowIsOpen);
  const isVncWindowMinimized = useSelector(VncSelectors.selectVncWindowIsMinimized);
  const isVncWindowExpanded = useSelector(VncSelectors.selectVncWindowIsExpanded);

  const isStatusModalVisible = useSelector(VncModalSelectors.selectVncModalStatusIsVisible);
  const isExpirationModalVisible = useSelector(VncModalSelectors.selectVncModalExpirationIsVisible);

  const hasCachedEnabled = (): boolean => {
    if (machineIdLocalStored) {
      return true;
    }

    return false;
  };
  const isVncOpened = vncUrlStatusV2 && hasCachedEnabled() && vncUrlStatusV2 === MachineVncStatus.Running;
  const isCheckingCacheStatus = isRequestingVncUrlLoading && hasCachedEnabled();
  const isVncLaunching = isCheckingCacheStatus || isRequestingVncUrlLoading || isGettingVncStatusV2Loading;
  const vncIframeClassName = classnames('vnc__body--iframe', {
    'vnc__body--iframe-expanded': isVncWindowExpanded,
    'vnc__body--iframe-small': !isVncWindowExpanded,
    'vnc__body--iframe-loading': isVncLaunching,
  });

  /**
   * @context VNC session is still valid but user force to hide the Iframe
   * @description Handle disconnect VNC session when clicking on "Exit remote control"
   *              in this case, the current session will be hidden
   *              and the status of this session will be cached in both local storage and VNC server)
   */
  const handleCloseVncWindow = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_VNC_KEY);
    localStorage.removeItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER);
    localStorage.removeItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);
    localStorage.removeItem(LOCAL_STORAGE_IS_USING_VNC_V2_KEY);
    dispatch(VncActions.vncRequestUrlV2ResetState());
    dispatch(VncActions.vncGetUrlStatusV2ResetState());
    dispatch(VncActions.vncGetUrlStatusV2Cancel());
    dispatch(VncActions.vncRequestUrlV2Cancel());
    dispatch(VncActions.vncCloseWindow());
    dispatch(VncActions.vncResetWindow());
  }, [dispatch]);

  const handleMinimizeVncWindow = useCallback(() => {
    dispatch(VncActions.vncMinimizeWindow());
  }, [dispatch]);

  const handleUnMinimizeVncWindow = useCallback(() => {
    dispatch(VncActions.vncUnMinimizeWindow());
  }, [dispatch]);

  const handleExpandVncWindow = useCallback(() => {
    dispatch(VncActions.vncExpandWindow());
  }, [dispatch]);

  const handleUnExpandVncWindow = useCallback(() => {
    dispatch(VncActions.vncUnExpandWindow());
  }, [dispatch]);

  const handleOpenCacheVncWindow = useCallback(() => {
    dispatch(VncActions.vncCacheWindow());
  }, [dispatch]);

  /**
   * @context initialing a new VNC session
   * @description Handle action to show timeout message when initialing a new VNC session
   */
  const handleHideVncStatusModal = useCallback(() => {
    dispatch(VncModalActions.vncModalStatusHide());
    handleCloseVncWindow();
  }, [dispatch, handleCloseVncWindow]);

  useEffect(() => {
    if (vncToken && isVncWindowOpen && vncSessionId) {
      dispatch(
        VncActions.vncGetUrlStatusV2Request({
          machineId: machineIdLocalStored || machineIdSelector,
          sessionId: vncSessionId,
        })
      );
    }
  }, [vncToken, dispatch, machineIdLocalStored, isVncWindowOpen, machineIdSelector, vncSessionId]);

  /**
   * @description to be executed every second to validate the session expiration state
   */
  useEffect(() => {
    nowIntervalRef.current = setInterval(() => {
      nowRef.current = new Date().toISOString();

      if (nowRef.current >= vncExpiresAt && vncToken) {
        if (isVncOpened) {
          dispatch(VncModalActions.vncModalExpirationShow());
        }
        dispatch(VncActions.vncRequestUrlV2ResetState());
        dispatch(VncActions.vncGetUrlStatusV2ResetState());
      }
    }, 1000);

    return () => clearInterval(nowIntervalRef.current);
  });

  /**
   * @description Double check if the session is cached in VNC server
   */
  useEffect(() => {
    if (hasCachedEnabled() && !vncToken && !isVncWindowOpen) {
      dispatch(
        VncActions.vncRequestUrlV2Request({
          machineId: machineIdLocalStored || '',
          softwareVersion: machineSoftwareVersionLocalStored,
        })
      );
      handleOpenCacheVncWindow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Close vnc session container when user can not get session endpoint
   */
  useEffect(() => {
    if (!vncToken && isRequestingVncUrlDone) {
      dispatch(VncActions.vncRequestUrlV2ResetState());
      handleCloseVncWindow();
      localStorage.removeItem(LOCAL_STORAGE_VNC_KEY);
      localStorage.removeItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER);
      localStorage.removeItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);
    }
  }, [dispatch, vncToken, isRequestingVncUrlDone, handleCloseVncWindow]);

  /**
   * @context Component unmount
   */
  useEffect(
    () => () => {
      dispatch(VncActions.vncRequestUrlV2ResetState());
    },
    [dispatch]
  );

  const title = t('vnc.title') + (serialNumber ? ` / ${serialNumber}` : '');

  const vncClassName = classnames('vncV2', {
    'vnc-expanded': isVncWindowExpanded,
    'vnc-small': !isVncWindowExpanded,
    'vnc-minimize': isVncWindowMinimized,
  });

  const renderDrawerExtra = (): JSX.Element => (
    <>
      <Button
        icon={<SvgIcon name="minimize" />}
        className="minimize-button"
        onClick={isVncWindowMinimized ? handleUnMinimizeVncWindow : handleMinimizeVncWindow}
        type="link"
      />
      {isVncWindowExpanded ? (
        <Button
          icon={<SvgIcon name="shrink" />}
          className="shrink-button"
          onClick={handleUnExpandVncWindow}
          type="link"
        />
      ) : (
        <Button
          icon={<SvgIcon name="expand" />}
          className="expand-button"
          onClick={handleExpandVncWindow}
          type="link"
        />
      )}
      <Button icon={<SvgIcon name="xmark" />} className="close-button" onClick={handleCloseVncWindow} type="link" />
    </>
  );

  return (
    <>
      {isVncWindowOpen && (
        <StyledVncV2 className={vncClassName}>
          <Drawer
            title={title}
            placement="bottom"
            closable={false}
            getContainer={false}
            mask={false}
            onClose={handleCloseVncWindow}
            destroyOnClose
            open={isVncWindowOpen}
            extra={renderDrawerExtra()}
          >
            <div className="vnc__body">
              {vncToken && vncRegion ? (
                <VncScreen
                  loadingUI={
                    <Spin tip={t('vnc.waitingTextV2')} spinning={isVncLaunching} size="large" className="spinning">
                      <img src={vncPlaceholder} alt="vnc-placeholder-inside" />
                    </Spin>
                  }
                  url={VncUtils.getVncUrl({ token: vncToken, region: vncRegion })}
                  scaleViewport
                  className={vncIframeClassName}
                  retryDuration={5000}
                  rfbOptions={{
                    wsProtocols: WS_PROTOCOLS,
                  }}
                />
              ) : (
                <Spin tip={t('vnc.waitingTextV2')} spinning={isVncLaunching} size="large" className="spinning">
                  <img src={vncPlaceholder} alt="vnc-placeholder" />
                </Spin>
              )}
            </div>
          </Drawer>
        </StyledVncV2>
      )}

      {isExpirationModalVisible && <VncExpirationModal />}
      {isStatusModalVisible && <VncStatusModal handleHideModal={handleHideVncStatusModal} />}
    </>
  );
};
