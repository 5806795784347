import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isNonEmptyEnvVar } from '../../../../lib/utils/env-variables/isNonEmptyEnvVar';
import logo from '../../../assets/images/kaercher-logo.svg';
import { useAnalyticsLinkActivated } from '../../../cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from '../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { StyledAppHeader } from './AppHeader.styles';
import { LegacyTextButton } from 'lib/components/LegacyButton/LegacyTextButton/LegacyTextButton';
import { Logout } from 'app/components/Logout/Logout';

export const AppHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const karcherBusinessUrl = process.env.REACT_APP_MY_KARCHER_BUSINESS_URL;

  return (
    <StyledAppHeader className="app-header">
      <div className="app-header__content">
        <Link
          to={'/'}
          onClick={(): void => {
            analyticsLinkActivated({
              linkName: AnalyticsLink.HEADER_LOGO,
            });
          }}
        >
          <img src={logo} alt="logo" />
        </Link>

        <div className="app-header__links">
          {isNonEmptyEnvVar(karcherBusinessUrl) && (
            <a href={karcherBusinessUrl} target="_blank" rel="noreferrer noopener">
              <LegacyTextButton className="app-header__link app-header__karcher-business-link">
                {t('karcherBusiness.headerLink.title')}
              </LegacyTextButton>
            </a>
          )}
        </div>

        <div className="app-header__logout">
          <Logout />
        </div>
      </div>
    </StyledAppHeader>
  );
};
