import styled from 'styled-components';

export const StyledVncV2 = styled.div`
  .ant-drawer {
    z-index: 1000;

    .ant-drawer-content-wrapper {
      max-width: 100% !important;
      border: none;

      .vnc__body--iframe-loading {
        position: absolute;
      }

      .vnc__body--iframe {
        canvas {
          width: 100% !important;
          height: 100% !important;
          max-width: 1280px;
          max-height: 800px;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  &.vnc-expanded {
    .ant-drawer-content-wrapper {
      height: 100% !important;

      .ant-drawer-content {
        .ant-drawer-header {
          height: 56px;
          max-height: 56px;
          padding: 0 32px;
          border: none;
          background-color: ${(props): string => props.theme.colors.black};

          .ant-drawer-header-title {
            .ant-drawer-title {
              ${(props): string => props.theme.fontStyles.p1.medium}
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: ${(props): string => props.theme.colors.white};
            }
          }

          .ant-drawer-extra {
            button {
              width: fit-content;
              margin-left: 16px;

              svg {
                width: 16px;
                height: 16px;

                path {
                  fill: ${(props): string => props.theme.colors.white};
                }
              }
            }

            .close-button {
              svg {
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        .ant-drawer-body {
          padding: 0 32px;
          background-color: ${(props): string => props.theme.colors.black};

          .vnc__body {
            width: 100%;
            height: 100%;

            .ant-spin-nested-loading {
              > div > .ant-spin > .ant-spin-text {
                text-shadow: none;
                color: ${(props): string => props.theme.colors.white};
                font-size: 12px;
                font-weight: bold;
              }

              .ant-spin {
                top: 15%;
              }

              img {
                display: block;
                width: 85%;
                height: 85%;
                margin-left: auto;
                margin-right: auto;
                filter: blur(3px);
              }
            }

            .vnc__body--iframe-expanded {
              width: 100%;
              max-height: 800px;
              min-height: 0;
              border: none;
              scale: 1;
              display: block;
              margin: auto;
            }
          }
        }
      }
    }
  }

  &.vnc-small {
    width: 360px;
    height: 242px;
    margin-right: 32px;
    position: absolute;
    bottom: 0;
    right: 0;

    .ant-drawer-content-wrapper {
      width: 360px !important;
      height: 242px !important;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border: none;

      .ant-drawer-content {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        .ant-drawer-header {
          height: 26px;
          border: none;
          padding: 10px 16px;
          padding-bottom: 0;
          background-color: ${(props): string => props.theme.colors.brightGrey};

          .ant-drawer-header-title {
            .ant-drawer-title {
              ${(props): string => props.theme.fontStyles.p1.medium}
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              color: ${(props): string => props.theme.colors.black};
            }
          }

          .ant-drawer-extra {
            button {
              width: fit-content;
              margin-left: 16px;

              svg {
                width: 16px;
                height: 16px;

                path {
                  fill: ${(props): string => props.theme.colors.black};
                }
              }
            }

            .close-button {
              svg {
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        .ant-drawer-body {
          padding: 10px 16px;
          padding-bottom: 0;
          background-color: ${(props): string => props.theme.colors.brightGrey};

          .vnc__body {
            .ant-spin-nested-loading {
              > div > .ant-spin > .ant-spin-text {
                text-shadow: none;
                color: ${(props): string => props.theme.colors.white};
                font-size: 12px;
                font-weight: bold;
              }

              img {
                display: block;
                width: 100%;
                height: 100%;
                filter: blur(1px);
              }
            }

            .vnc__body--iframe-small {
              width: inherit;
              height: 200px;
              border: none;
            }
          }
        }
      }
    }
  }

  &.vnc-minimize {
    .ant-drawer-content-wrapper {
      height: 32px !important;

      .ant-drawer-content {
        overflow: hidden;
      }
    }
  }

  @media screen and (min-width: 1441px) {
    .vnc__body {
      .ant-spin-nested-loading {
        max-width: 1280px;
        max-height: 800px;
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 1441px) {
    .vnc__body--iframe {
      canvas {
        max-height: 740px !important;
      }
    }
  }
`;
