import { matchRoutes, useLocation } from 'react-router-dom';
import { Optional } from '../../../../lib/types/Optional';
import { useRoutes } from './useRoutes';

export const useCurrentPathPattern = (): Optional<string> => {
  const location = useLocation();
  const routes = useRoutes();
  const matches = matchRoutes(routes, location);

  if (!matches) return null;

  const match = matches[matches.length - 1];
  return match.route.path;
};
