/* eslint-disable max-len */

export function addFullscreenControl({ controlDiv, onClick }: { controlDiv: Element; onClick: () => void }): void {
  const fullscreenButton = document.createElement('button');
  fullscreenButton.className = 'map__fullscreen-button';
  controlDiv.appendChild(fullscreenButton);

  const fullscreenButtonImg = document.createElement('img');
  fullscreenButtonImg.className = 'map__fullscreen-button-img';
  fullscreenButtonImg.src =
    'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%200v6h2V2h4V0H0zm16%200h-4v2h4v4h2V0h-2zm0%2016h-4v2h6v-6h-2v4zM2%2012H0v6h6v-2H2v-4z%22/%3E%3C/svg%3E';
  fullscreenButton.appendChild(fullscreenButtonImg);

  const enabledFullscreenButtonImg = document.createElement('img');
  enabledFullscreenButtonImg.className = 'map__enabled-fullscreen-button-img';
  enabledFullscreenButtonImg.src =
    'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M4%204H0v2h6V0H4v4zm10%200V0h-2v6h6V4h-4zm-2%2014h2v-4h4v-2h-6v6zM0%2014h4v4h2v-6H0v2z%22/%3E%3C/svg%3E';
  fullscreenButton.appendChild(enabledFullscreenButtonImg);

  fullscreenButton.addEventListener('click', onClick);
}
