import { ThemeConfig } from 'antd';
import { DefaultTheme } from 'styled-components';
import { singleton } from 'tsyringe';
import { antDesignComponentTokens, antDesignToken, theme } from '../../../config/theme';

@singleton()
export class Styling {
  public getStyledComponentsTheme = (): DefaultTheme => theme;

  public getAntDesignThemeConfig = (): ThemeConfig => ({
    token: antDesignToken,
    components: antDesignComponentTokens,
  });
}
