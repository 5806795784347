import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Col, Divider, Row, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { PRODUCT_NAME_TITLE } from '../../../../../config/constants';
import { ThemeConstants } from '../../../../../config/theme';
import { selectIsAuthorizedFromSupportPortal } from '../../state/authenticationSelectors';
import unauthorized from '../../../../assets/images/auth/unauthorized.png';
import { StyledUnauthorized } from './Unauthorized.styles';
import { RoutePaths } from 'config/route-paths';

export const Unauthorized = (): JSX.Element => {
  const { t } = useTranslation();
  const isAuthorizedFromSupportPortal = useSelector(selectIsAuthorizedFromSupportPortal);
  const contentListPoints: string[] = t('unauthorized.contentListPoints', { returnObjects: true });

  if (isAuthorizedFromSupportPortal || isAuthorizedFromSupportPortal === undefined) {
    return <Navigate to={RoutePaths.ROOT} />;
  }

  return (
    <StyledUnauthorized className="unauthorized">
      <Row className="unauthorized__header">
        <h2 className="unauthorized__header-name">{PRODUCT_NAME_TITLE}</h2>
      </Row>
      <div className="unauthorized__body">
        <div className="unauthorized__body-content">
          <div className="unauthorized__container--narrow">
            <Row className="unauthorized__banner" gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]}>
              <Col xs={24} sm={24} md={9} lg={9} xl={9} className="unauthorized__banner-image">
                <Image src={unauthorized} preview={false} alt="unauthorized" />
              </Col>
              <Col xs={24} sm={24} md={15} lg={15} xl={15} className="unauthorized__banner-desc">
                <h3>{t('unauthorized.bannerDesc')}</h3>
              </Col>
            </Row>
            <Divider />
            <div className="unauthorized__content-list">
              <p>{t('unauthorized.contentList')}</p>
              <ul>
                {contentListPoints.map(contentListPoint => (
                  <li key={contentListPoint}>{contentListPoint}</li>
                ))}
              </ul>
            </div>
            <p className="unauthorized__content-access-desc">{t('unauthorized.contentAccessDesc')}</p>
          </div>
        </div>
      </div>
    </StyledUnauthorized>
  );
};
