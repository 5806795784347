import styled from 'styled-components';

export const StyledAppFooter = styled.div`
  flex: 0 0;
  padding: 0;
  background-color: ${(props): string => props.theme.colors.white};

  ${(props): string => props.theme.fontStyles.redesign.sm.normal};
  line-height: ${(props): string => props.theme.lineHeights.redesign.lg};
  color: ${(props): string => props.theme.colors.black};

  .footer__banner {
    padding: ${(props): string => props.theme.spacing.base} 78px;
    background-color: ${(props): string => props.theme.colors.brightGrey};
  }

  .footer__links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: ${(props): string => props.theme.spacing.base};
  }

  .footer__legal-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${(props): string => props.theme.spacing.xxl};
  }

  a {
    color: ${(props): string => props.theme.colors.black};
    text-decoration: none;

    :hover,
    :focus,
    :active {
      color: ${(props): string => props.theme.colors.black};
    }
  }

  .footer__copyright {
    display: flex;
    justify-content: space-between;
    padding: ${(props): string => props.theme.spacing.base} 78px;
  }
`;
