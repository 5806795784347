import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IAnalyticsUserAttributes } from '../interfaces/Analytics.types';
import { AnalyticsActions } from '../state/analyticsSlice';

/**
 * Hook to add user attributes for analytics.
 * The object provided as function argument is merged with the existing state.
 */
export function useAnalyticsAddUserAttributes(userAttributes: Partial<IAnalyticsUserAttributes>): void {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AnalyticsActions.addUserAttributes(userAttributes));

    return () => {
      dispatch(AnalyticsActions.setUserAttributes({}));
    };
  }, [dispatch, userAttributes]);
}
