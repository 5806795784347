import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnalyticsActions } from '../state/analyticsSlice';
import { Optional } from 'lib/types/Optional';

export type useAnalyticsSetSearchOptions = Optional<string>;

/**
 * Hook to set the search term for analytics
 */
export function useAnalyticsSetSearch(searchTerm: useAnalyticsSetSearchOptions): void {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AnalyticsActions.setSearch(searchTerm));

    return () => {
      dispatch(AnalyticsActions.setSearch(null));
    };
  }, [dispatch, searchTerm]);
}
