import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ScrollToTop } from '../../../../lib/components/ScrollToTop/ScrollToTop';
import { AppHeader } from '../AppHeader/AppHeader';
import { AppFooter } from '../AppFooter/AppFooter';
import { SideNav } from '../SideNav/SideNav';
import { StyledLayout } from './AppLayout.styles';
import { ScrollToTopButton } from 'lib/components/ScrollToTopButton/ScrollToTopButton';
import { Vnc } from 'app/components/Vnc/components/Vnc';
import {
  LOCAL_STORAGE_IS_USING_VNC_V2_KEY,
  LOCAL_STORAGE_VNC_KEY,
  LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY,
  LOCAL_STORAGE_VNC_SERIAL_NUMBER,
} from 'config/constants';
import { VncSelectors } from 'app/components/Vnc/state/vncSelectors';
import {
  selectIsCheckingAuthentication,
  selectIsLoggedIn,
} from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { MachineDetailsDrawer } from 'app/cross-cutting-concerns/drawers/components/MachineDetailsDrawer/MachineDetailsDrawer';
import { SiteDetailsDrawer } from 'app/cross-cutting-concerns/drawers/components/SiteDetailsDrawer/SiteDetailsDrawer';
import { VncV2 } from 'app/components/Vnc/components/VncV2';
import { VncUtils } from 'app/components/Vnc/util/VncUtils';

const { Content } = Layout;

export const AppLayout = (): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const machineIdLocalStored = localStorage.getItem(LOCAL_STORAGE_VNC_KEY);
  const softwareVersionLocalStored = localStorage.getItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);
  const isUsingVncV2Solution = VncUtils.isUsingVncV2Solution(softwareVersionLocalStored);
  const isUsingCachedVncV2 = localStorage.getItem('LOCAL_STORAGE_IS_USING_VNC_V2_KEY') === 'true';

  const isVncWindowOpen = useSelector(VncSelectors.selectVncWindowIsOpen);
  const isOpenVncWindowV2 = useSelector(VncSelectors.selectVncWindowIsOpenV2);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isCheckingAuthentication = useSelector(selectIsCheckingAuthentication);

  const isVncComponentAvailable = useMemo(
    () => (machineIdLocalStored || isVncWindowOpen) && isLoggedIn,
    [machineIdLocalStored, isVncWindowOpen, isLoggedIn]
  );

  useEffect(() => {
    if (!isLoggedIn && !isCheckingAuthentication) {
      localStorage.removeItem(LOCAL_STORAGE_VNC_KEY);
      localStorage.removeItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER);
      localStorage.removeItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);
      localStorage.removeItem(LOCAL_STORAGE_IS_USING_VNC_V2_KEY);
    }
  }, [isLoggedIn, isCheckingAuthentication]);

  const renderVnc = (): JSX.Element =>
    isUsingVncV2Solution && (isOpenVncWindowV2 || isUsingCachedVncV2) ? <VncV2 /> : <Vnc />;

  return (
    <StyledLayout className="layout">
      <AppHeader />
      <Layout hasSider>
        <SideNav />
        <ScrollToTop containerRef={containerRef} />
        <Layout className="layout-content">
          <Content>
            <div ref={containerRef} className="outlet-wrapper">
              <div className="layout__grey-bar" />
              <Outlet />
              {!isVncComponentAvailable ? <></> : renderVnc()}
              <MachineDetailsDrawer />
              <SiteDetailsDrawer />
            </div>
            <AppFooter>
              <ScrollToTopButton containerRef={containerRef} />
            </AppFooter>
          </Content>
        </Layout>
      </Layout>
    </StyledLayout>
  );
};
