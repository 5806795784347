import { produce } from 'immer';
import { AnyAction, Reducer } from 'redux';
import { Optional } from '../../../../lib/types/Optional';
import { IMachineClassificationAvailabilityConfig } from '../../../modules/machine-inventory/interfaces/Machine.types';
import { initialClassificationAvailabilityState } from '../constants';
import { IPermission, IUserInfo } from '../interfaces/Authentication.types';
import { IAuthenticationTokens } from '../interfaces/IAuthenticationTokens';
import { AuthenticationActions } from './authenticationActions';

export interface IAuthenticationState {
  isCheckingAuthentication: boolean;
  isLoggedIn: boolean;
  tokens: Optional<IAuthenticationTokens>;
  permissions: IPermission[];
  userInfo: IUserInfo;
  classificationAvailabilityConfig: {
    config: IMachineClassificationAvailabilityConfig;
    isLoading: boolean;
    isInitialized: boolean;
  };
  isAuthorizedFromSupportPortal: boolean | undefined;
}

export const initialState: IAuthenticationState = {
  isCheckingAuthentication: true,
  isLoggedIn: false,
  tokens: null,
  permissions: [],
  userInfo: {},
  classificationAvailabilityConfig: {
    config: initialClassificationAvailabilityState,
    isInitialized: false,
    isLoading: false,
  },
  isAuthorizedFromSupportPortal: undefined,
};

export const authenticationReducer: Reducer<IAuthenticationState, AnyAction> = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case AuthenticationActions.CHECK_AUTHENTICATION: {
        draft.isCheckingAuthentication = true;
        return draft;
      }

      case AuthenticationActions.CHECK_AUTHENTICATION_AUTHENTICATED: {
        const { tokens, permissions } = action.payload;

        draft.isCheckingAuthentication = false;
        draft.isLoggedIn = true;
        draft.tokens = tokens;
        draft.permissions = permissions;
        return draft;
      }

      case AuthenticationActions.CHECK_AUTHENTICATION_NOT_AUTHENTICATED: {
        draft.isCheckingAuthentication = false;
        return draft;
      }

      case AuthenticationActions.TOKEN_REFRESHED: {
        const { tokens, permissions } = action.payload;

        draft.isLoggedIn = true;
        draft.tokens = tokens;
        draft.permissions = permissions;
        return draft;
      }

      case AuthenticationActions.SET_USER_INFO: {
        const { userInfo } = action.payload ?? {};

        draft.userInfo.customerId = userInfo?.['custom:customerId'];
        draft.userInfo.email = userInfo?.email;
        draft.userInfo.title = userInfo?.['custom:title'];
        draft.userInfo.displayName = userInfo?.['custom:displayName'];
        draft.userInfo.phoneNumber = userInfo?.['custom:phoneNumber'];
        draft.userInfo.preferredLanguage = userInfo?.['custom:preferredLanguage'];
        return draft;
      }

      case AuthenticationActions.GET_CLASSIFICATION_AVAILABILITY_REQUEST: {
        draft.classificationAvailabilityConfig.isLoading = true;
        draft.classificationAvailabilityConfig.isInitialized = false;
        return draft;
      }

      case AuthenticationActions.GET_CLASSIFICATION_AVAILABILITY_SUCCESS: {
        draft.classificationAvailabilityConfig.config = action.payload;
        draft.classificationAvailabilityConfig.isInitialized = true;
        draft.classificationAvailabilityConfig.isLoading = false;
        return draft;
      }

      case AuthenticationActions.GET_CLASSIFICATION_AVAILABILITY_ERROR: {
        draft.classificationAvailabilityConfig.isInitialized = true;
        draft.classificationAvailabilityConfig.isLoading = false;
        return draft;
      }

      case AuthenticationActions.SET_CLASSIFICATION_AVAILABILITY_CONFIG: {
        draft.classificationAvailabilityConfig.config = action.payload;
        return draft;
      }

      case AuthenticationActions.SET_IS_AUTHORIZED_FROM_SUPPORT_PORTAL: {
        const { data } = action.payload ?? {};
        draft.isAuthorizedFromSupportPortal = data;
        return draft;
      }

      default:
        return draft;
    }
  });
