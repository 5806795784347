import { IPermission } from '../interfaces/Authentication.types';

/**
 * @param  {IPermission[]} userPermissions - Permissions of the user
 * @param  {IPermission[]} requiredPermissions - An array of strings. The user needs to have **only one** of the
 * specified permissions to be permitted.
 * @return  {boolean} Whether or not the user is permitted
 */
export function isUserPermitted(userPermissions: IPermission[], requiredPermissions: IPermission[]): boolean {
  if (requiredPermissions.length === 0) return true;

  return requiredPermissions.some(requiredPermission => userPermissions.includes(requiredPermission));
}
