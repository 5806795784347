import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AnalyticsActions } from '../state/analyticsSlice';

export type AnalyticsLinkActivatedFunction = (options: AnalyticsLinkActivatedOptions) => void;
export interface AnalyticsLinkActivatedOptions {
  linkName: string;
}
/**
 * Hook that returns a function to report link activation to analytics
 */
export function useAnalyticsLinkActivated(): AnalyticsLinkActivatedFunction {
  const dispatch = useDispatch();

  const linkActivatedFunction = useCallback(
    ({ linkName }: AnalyticsLinkActivatedOptions) => {
      dispatch(AnalyticsActions.linkActivated({ linkName }));
    },
    [dispatch]
  );

  return linkActivatedFunction;
}
