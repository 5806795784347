import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IAnalyticsSiteAttributes } from '../interfaces/Analytics.types';
import { AnalyticsActions } from '../state/analyticsSlice';

/**
 * Hook to set the site attributes for analytics
 */
export function useAnalyticsSetSiteAttributes(siteAttributes: Partial<IAnalyticsSiteAttributes>): void {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AnalyticsActions.setSiteAttributes(siteAttributes));

    return () => {
      dispatch(AnalyticsActions.setSiteAttributes({}));
    };
  }, [dispatch, siteAttributes]);
}
