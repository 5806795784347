import isNil from 'lodash-es/isNil';
import { inject, injectable } from 'tsyringe';
import type {
  BrowserStorageAdapter,
  BrowserStorageGetOptions,
  BrowserStorageSetOptions,
} from './interface/BrowserStorage.types';

@injectable()
export class BrowserStorage {
  private static EM_BROWSER_STORAGE_PREFIX = 'em-browser-storage-';

  constructor(@inject('BrowserStorageAdapter') private readonly storageAdapter: BrowserStorageAdapter) {}

  public get(key: string, options?: BrowserStorageGetOptions): string | null {
    const storageValue = this.storageAdapter.get(BrowserStorage.EM_BROWSER_STORAGE_PREFIX + key, options);

    if (isNil(storageValue)) {
      return null;
    }

    if (options?.deobfuscate) {
      return this.deobfuscate(storageValue);
    }

    return storageValue;
  }

  public set(key: string, value: string, options?: BrowserStorageSetOptions): void {
    let storageValue: string;

    if (options?.obfuscate) {
      storageValue = this.obfuscate(value);
    } else {
      storageValue = value;
    }

    this.storageAdapter.set(BrowserStorage.EM_BROWSER_STORAGE_PREFIX + key, storageValue, options);
  }

  private obfuscate(unobfuscatedString: string): string {
    if (unobfuscatedString.length <= 0) {
      return '';
    }

    const encodedString = new TextEncoder().encode(unobfuscatedString);
    const binaryString = Array.from(encodedString, x => String.fromCodePoint(x)).join('');
    const base64encodedString = btoa(binaryString);

    return base64encodedString;
  }

  private deobfuscate(obfuscatedString: string): string {
    if (obfuscatedString.length <= 0) {
      return '';
    }

    const binaryString = atob(obfuscatedString);
    const decodedString = Uint8Array.from(binaryString, (m: string): number => {
      const codePoint = m.codePointAt(0);

      if (codePoint === undefined) {
        throw new Error('Invalid input value.');
      }
      return codePoint;
    });
    const unobfuscatedString = new TextDecoder().decode(decodedString);

    return unobfuscatedString;
  }
}
