import { createSlice } from '@reduxjs/toolkit';
import * as VncActionsTypes from './vncActions.types';
import * as VncStateTypes from './vncState.types';

const initialVncUrlState: VncStateTypes.IVncUrl = {
  url: null,
  expiresAt: null,
  machineId: null,
  softwareVersion: null,
  isLoading: null,
  isCallingDone: null,
};

const initialVncUrlStatusState: VncStateTypes.IVncUrlStatus = {
  status: null,
  isLoading: null,
};

const initialVncUrlV2State: VncStateTypes.IVncUrlV2 = {
  token: null,
  region: null,
  expiresAt: null,
  sessionId: null,
  machineId: null,
  softwareVersion: null,
  isLoading: null,
  isCallingDone: null,
};

const initialVncUrlStatusV2State: VncStateTypes.IVncUrlStatusV2 = {
  statusV2: null,
  isLoading: null,
};

const initialVncWindowState: VncStateTypes.IVncWindow = {
  isOpen: null,
  isExpanded: null,
  isMinimized: null,
  isOpenV2: null,
};

export const initialState: VncStateTypes.IVncState = {
  vncUrl: initialVncUrlState,
  vncStatus: initialVncUrlStatusState,
  vncUrlV2: initialVncUrlV2State,
  vncStatusV2: initialVncUrlStatusV2State,
  vncWindow: initialVncWindowState,
};

const vncSlice = createSlice({
  name: 'vnc',
  initialState,
  reducers: {
    // Requesting for vnc url
    vncRequestUrlRequest: (state, action: VncActionsTypes.VncRequestUrlRequestAction) => {
      state.vncUrl.isLoading = true;
      state.vncUrl.machineId = action.payload.machineId;
      state.vncUrl.softwareVersion = action.payload.softwareVersion;
      return state;
    },
    vncRequestUrlSuccess: (state, action: VncActionsTypes.VncRequestUrlSuccessAction) => {
      if (action.payload?.data) {
        const { url, expiresAt } = action.payload.data;

        const earlier3MExpiresAtTimestamp = Date.parse(expiresAt) - 120000;
        const earlier3MExpiresAt = new Date(earlier3MExpiresAtTimestamp).toISOString();

        state.vncUrl.url = url || initialVncUrlState.url;
        state.vncUrl.expiresAt = earlier3MExpiresAt || initialVncUrlState.expiresAt;

        // NOTE: This helps the loading not getting interrupted when changing from requesting url to polling status
        state.vncStatus.isLoading = true;
      }

      state.vncUrl.isLoading = false;
      state.vncUrl.isCallingDone = true;
      return state;
    },
    vncRequestUrlError: (state, _action: VncActionsTypes.VncRequestUrlErrorAction) => {
      state.vncUrl.isLoading = false;
      state.vncUrl.isCallingDone = true;
      return state;
    },
    vncRequestUrlResetState: state => {
      state.vncUrl = initialVncUrlState;
      return state;
    },

    vncRequestUrlCancel: (state, _action: VncActionsTypes.VncRequestUrlCancelAction) => {
      state.vncUrl = initialVncUrlState;
      return state;
    },

    // Getting for vnc url status
    vncGetUrlStatusRequest: (state, _action: VncActionsTypes.VncGetUrlStatusRequestAction) => {
      state.vncStatus.isLoading = true;
      return state;
    },
    vncGetUrlStatusSuccess: (state, action: VncActionsTypes.VncGetUrlStatusSuccessAction) => {
      if (action.payload.data) {
        const { status } = action.payload.data;

        state.vncStatus.status = status || initialVncUrlStatusState.status;
      }

      state.vncStatus.isLoading = false;
      return state;
    },
    vncGetUrlStatusError: (state, _action: VncActionsTypes.VncGetUrlStatusErrorAction) => {
      state.vncStatus.isLoading = false;
      return state;
    },
    vncGetUrlStatusResetState: state => {
      state.vncStatus = initialVncUrlStatusState;
      return state;
    },
    vncGetUrlStatusCancel: (state, _action: VncActionsTypes.VncGetUrlStatusCancelAction) => {
      state.vncStatus = initialVncUrlStatusState;
      return state;
    },

    // Requesting for vnc url v2
    vncRequestUrlV2Request: (state, action: VncActionsTypes.VncRequestUrlRequestAction) => {
      state.vncUrlV2.isLoading = true;
      state.vncUrlV2.machineId = action.payload.machineId;
      state.vncUrlV2.softwareVersion = action.payload.softwareVersion;
      return state;
    },
    vncRequestUrlV2Success: (state, action: VncActionsTypes.VncRequestUrlV2SuccessAction) => {
      if (action.payload?.data) {
        const { token, region, expiresAt, sessionId } = action.payload.data;

        const earlier3MExpiresAtTimestamp = Date.parse(expiresAt) - 120000;
        const earlier3MExpiresAt = new Date(earlier3MExpiresAtTimestamp).toISOString();

        state.vncUrlV2.token = token || initialVncUrlV2State.token;
        state.vncUrlV2.region = region || initialVncUrlV2State.region;
        state.vncUrlV2.expiresAt = earlier3MExpiresAt || initialVncUrlV2State.expiresAt;
        state.vncUrlV2.sessionId = sessionId || initialVncUrlV2State.sessionId;

        // NOTE: This helps the loading not getting interrupted when changing from requesting url to polling status
        state.vncStatusV2.isLoading = true;
      }

      state.vncUrlV2.isLoading = false;
      state.vncUrlV2.isCallingDone = true;
      return state;
    },
    vncRequestUrlV2Error: (state, _action: VncActionsTypes.VncRequestUrlV2ErrorAction) => {
      state.vncUrlV2.isLoading = false;
      state.vncUrlV2.isCallingDone = true;
      return state;
    },

    vncRequestUrlV2ResetState: state => {
      state.vncUrlV2 = initialVncUrlV2State;
      return state;
    },

    vncRequestUrlV2Cancel: (state, _action: VncActionsTypes.VncRequestUrlV2CancelAction) => {
      state.vncUrlV2 = initialVncUrlV2State;
      return state;
    },

    vncGetUrlStatusV2Request: (state, _action: VncActionsTypes.VncGetUrlStatusV2RequestAction) => {
      state.vncStatusV2.isLoading = true;
      return state;
    },
    vncGetUrlStatusV2Success: (state, action: VncActionsTypes.VncGetUrlStatusV2SuccessAction) => {
      if (action.payload.data) {
        const { status } = action.payload.data;

        state.vncStatusV2.statusV2 = status || initialVncUrlStatusV2State.statusV2;
      }

      state.vncStatusV2.isLoading = false;
      return state;
    },
    vncGetUrlStatusV2Error: (state, _action: VncActionsTypes.VncGetUrlStatusV2ErrorAction) => {
      state.vncStatusV2.isLoading = false;
      return state;
    },
    vncGetUrlStatusV2ResetState: state => {
      state.vncStatusV2 = initialVncUrlStatusV2State;
      return state;
    },
    vncGetUrlStatusV2Cancel: (state, _action: VncActionsTypes.VncGetUrlStatusV2CancelAction) => {
      state.vncStatusV2 = initialVncUrlStatusV2State;
    },

    // Vnc window actions
    vncOpenWindow: state => {
      state.vncWindow.isOpen = true;
      state.vncWindow.isExpanded = true;
      return state;
    },
    vncCloseWindow: state => {
      state.vncWindow = initialVncWindowState;
      return state;
    },
    vncExpandWindow: state => {
      state.vncWindow.isExpanded = true;
      state.vncWindow.isMinimized = false;
      return state;
    },
    vncUnExpandWindow: state => {
      state.vncWindow.isExpanded = false;
      state.vncWindow.isMinimized = false;
      return state;
    },
    vncMinimizeWindow: state => {
      state.vncWindow.isExpanded = false;
      state.vncWindow.isMinimized = true;
      return state;
    },
    vncUnMinimizeWindow: state => {
      state.vncWindow.isExpanded = false;
      state.vncWindow.isMinimized = false;
      return state;
    },
    vncResetWindow: state => {
      state.vncWindow = initialVncWindowState;
      return state;
    },
    vncCacheWindow: state => {
      state.vncWindow.isOpen = true;
      state.vncWindow.isExpanded = false;
      state.vncWindow.isMinimized = false;
      return state;
    },
    vncShowWindowV2: state => {
      state.vncWindow.isOpenV2 = true;
      return state;
    },
    vncHideWindowV2: state => {
      state.vncWindow.isOpenV2 = false;
      return state;
    },
  },
});

export const VncActions = vncSlice.actions;
export const vncReducer = vncSlice.reducer;
