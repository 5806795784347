import React from 'react';
import { singleton } from 'tsyringe';
import { RoutePaths } from '../../../config/route-paths';
import { AppLayout } from '../../components/app-layout/AppLayout/AppLayout';
import { TermsConditionsGuard } from '../../modules/terms-conditions/components/TermsConditionsGuard/TermsConditionsGuard';
import { AuthenticationService } from '../authentication/AuthenticationService';
import { AuthorizationGuard } from '../authentication/components/AuthorizationGuard/AuthorizationGuard';
import { FeatureFlagGuard } from '../feature-flags/components/FeatureFlag/FeatureFlagGuard';
import { FeatureFlagService } from '../feature-flags/FeatureFlagService';
import { RoutingGuard } from './components/RoutingGuard/RoutingGuard';
import { PublicRouteWithFeatureFlagError } from './errors/PublicRouteWithFeatureFlagError';
import { IRoute } from './interfaces/Routing.types';
import {
  privateNotificationRoutes,
  privateRoutes as unprotectedPrivateRoutes,
  privateRoutesWithoutLayout as unprotectedPrivateRoutesWithoutLayout,
  privateServiceRoutes,
  privateUserRoutes,
  publicRoutes,
  termsAndConditionRoutes as unprotectedTermsAndConditionRoutes,
} from 'config/routes';

@singleton()
export class Routing {
  constructor(private authenticationService: AuthenticationService, private featureFlagService: FeatureFlagService) {}

  public getAllRoutesWithAppLayout(): IRoute[] {
    const routes: IRoute[] = [
      {
        path: RoutePaths.ROOT,
        element: <AppLayout />,
        children: this.getProtectedPrivateRoutes(),
        permissions: [],
      },
    ];

    routes.push(...this.getProtectedPrivateRoutesWithoutLayout());
    routes.push(...this.getTermsAndConditionRoutes());
    routes.push(...this.getPublicRoutes());

    return routes;
  }

  public async getRoutesForMenuItems(): Promise<IRoute[]> {
    const privateRoutes = this.getPrivateRoutes();

    const userPermissions = await this.authenticationService.getPermissions();

    const privateRoutesFilteredByPermission = privateRoutes.filter(route => {
      if (route.permissions.length <= 0) {
        return true;
      }

      return route.permissions.some(routePermission => userPermissions.includes(routePermission));
    });

    const privateAndPublicRoutes = privateRoutesFilteredByPermission.concat(this.getPublicRoutes());

    const privateAndPublicRoutesFilteredByFeatureFlag = privateAndPublicRoutes.filter(route => {
      if (route.featureFlag === undefined) {
        return true;
      }

      return this.featureFlagService.isFeatureEnabled(route.featureFlag);
    });

    return privateAndPublicRoutesFilteredByFeatureFlag;
  }

  public getPublicRoutes(): IRoute[] {
    publicRoutes.forEach(route => {
      if (route.featureFlag) {
        throw new PublicRouteWithFeatureFlagError(route);
      }
    });

    return publicRoutes;
  }

  public getPrivateRoutes(): IRoute[] {
    const effectiveRoutes = unprotectedPrivateRoutes.concat(
      privateNotificationRoutes,
      privateServiceRoutes,
      privateUserRoutes
    );

    return effectiveRoutes;
  }

  public getProtectedPrivateRoutes(): IRoute[] {
    const privateRoutes: IRoute[] = this.getPrivateRoutes();

    return privateRoutes.map(route => ({
      ...route,
      element: (
        <AuthorizationGuard route={route}>
          <FeatureFlagGuard route={route}>
            <TermsConditionsGuard>
              <RoutingGuard route={route}>{route.element}</RoutingGuard>
            </TermsConditionsGuard>
          </FeatureFlagGuard>
        </AuthorizationGuard>
      ),
    }));
  }

  public getPrivateRoutesWithoutAppLayout(): IRoute[] {
    return unprotectedPrivateRoutesWithoutLayout;
  }

  public getProtectedPrivateRoutesWithoutLayout(): IRoute[] {
    const privateRoutesWithoutLayout: IRoute[] = this.getPrivateRoutesWithoutAppLayout();

    return privateRoutesWithoutLayout.map(route => ({
      ...route,
      element: (
        <AuthorizationGuard route={route}>
          <FeatureFlagGuard route={route}>
            <TermsConditionsGuard>
              <RoutingGuard route={route}>{route.element}</RoutingGuard>
            </TermsConditionsGuard>
          </FeatureFlagGuard>
        </AuthorizationGuard>
      ),
    }));
  }

  public getTermsAndConditionRoutes(): IRoute[] {
    const termsAndConditionRoutes: IRoute[] = unprotectedTermsAndConditionRoutes;

    return termsAndConditionRoutes.map(route => ({
      ...route,
      element: (
        <AuthorizationGuard route={route}>
          <FeatureFlagGuard route={route}>
            <RoutingGuard route={route}>{route.element}</RoutingGuard>
          </FeatureFlagGuard>
        </AuthorizationGuard>
      ),
    }));
  }
}
