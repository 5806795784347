import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../lib/components/Button/PrimaryButton/PrimaryButton';
import { useAnalyticsLinkActivated } from '../../../analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetPageInfo } from '../../../analytics/hooks/useAnalyticsSetPageInfo';
import { AuthenticationActions } from '../../state/authenticationActions';
import { StyledLogin } from './Login.styles';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';

export const Login = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const analyticsLinkActivated = useAnalyticsLinkActivated();
  useAnalyticsSetPageInfo({});

  const onLogin = useCallback((): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.LOGIN,
    });
    dispatch(AuthenticationActions.signIn());
  }, [analyticsLinkActivated, dispatch]);

  return (
    <StyledLogin className="login">
      <div className="login__image">
        <div className="login__tint" />
      </div>
      <div className="login__text">
        <div className="login__heading">{t('login.heading')}</div>
        <div className="login__subheading">{t('login.subheading')}</div>
      </div>
      <div className="login__buttons">
        <PrimaryButton size="m" className="login__login-button" onClick={onLogin}>
          {t('login.loginButton')}
        </PrimaryButton>
      </div>
    </StyledLogin>
  );
};
