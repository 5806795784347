import { call, getContext, put, takeLatest } from 'typed-redux-saga';
import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { IFeatureFlagConfig } from '../../../../config/feature-flags';
import { IDependencies } from '../../dependency-injection/interfaces/IDependencies';
import { FeatureFlagActions } from './featureFlagSlice';

export function* getFeatureFlagConfigRequestSaga(): Generator<
  CallEffect<IFeatureFlagConfig> | PutEffect | GetContextEffect,
  void,
  IDependencies
> {
  const { featureFlagService } = yield* getContext<IDependencies>('dependencies');

  try {
    // This syntax is required to preserve the this binding within the feature flag service
    // It is a call effects equivalent of something like this:
    // `featureFlagService.getEffectiveFeatureFlagConfig.bind(featureFlagService)`
    const featureFlagConfigResponse = yield* call([
      featureFlagService,
      featureFlagService.getEffectiveFeatureFlagConfig,
    ]);

    featureFlagService.persistFeatureFlagConfig(featureFlagConfigResponse);

    yield* put(FeatureFlagActions.getFeatureFlagConfigSuccess(featureFlagConfigResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      FeatureFlagActions.getFeatureFlagConfigError({
        error,
      })
    );
  }
}

export function* featureFlagSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(FeatureFlagActions.getFeatureFlagConfigRequest, getFeatureFlagConfigRequestSaga);
}
