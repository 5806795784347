import { MachineSubscriptionType } from '../../../modules/machine-inventory/interfaces/MachineSubscription.types';
import { GraphQlSubscriptionType } from '../interfaces/GraphQlSubscription.types';

export class GraphQlUtils {
  public static getThrottleDurationBySubscriptionType(type: GraphQlSubscriptionType): number {
    const mappedThrottleDuration = this.throttleDurationBySubscriptionTypeMap[type];

    if (mappedThrottleDuration === undefined) {
      return this.defaultThrottleDuration;
    }

    return mappedThrottleDuration;
  }

  private static defaultThrottleDuration = 0;

  private static throttleDurationBySubscriptionTypeMap: Record<GraphQlSubscriptionType, number> = {
    [MachineSubscriptionType.MACHINE_UPDATE]: 0,
  };
}
