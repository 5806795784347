import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../../../config/route-paths';
import { FeatureFlagSelectors } from '../../../feature-flags/state/featureFlagSelectors';
import { FeatureFlagActions } from '../../../feature-flags/state/featureFlagSlice';
import { AuthenticationActions } from '../../state/authenticationActions';
import { selectIsClassificationAvailabilityConfigInitialized } from '../../state/authenticationSelectors';

export const Init = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const areFeatureFlagsInitialized = useSelector(FeatureFlagSelectors.selectAreFeatureFlagsInitialized);
  const isClassificationAvailabilityConfigInitialized = useSelector(
    selectIsClassificationAvailabilityConfigInitialized
  );

  useEffect(() => {
    dispatch(FeatureFlagActions.getFeatureFlagConfigRequest());
    dispatch(AuthenticationActions.getClassificationAvailabilityRequest());
  }, [dispatch]);

  useEffect(() => {
    if (areFeatureFlagsInitialized && isClassificationAvailabilityConfigInitialized) {
      navigate(RoutePaths.ROOT);
    }
  }, [areFeatureFlagsInitialized, isClassificationAvailabilityConfigInitialized, navigate]);

  return <></>;
};
