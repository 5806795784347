import { call, takeLatest } from 'typed-redux-saga';
import { CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { IDependencies } from '../../dependency-injection/interfaces/IDependencies';
import { AnalyticsConstants } from '../AnalyticsConstants';
import { IAnalyticsLinkActivatedAction } from './analyticsActions.types';
import { AnalyticsActions } from './analyticsSlice';

export function* linkActivatedSaga(
  action: IAnalyticsLinkActivatedAction
): Generator<CallEffect<void> | PutEffect, void, IDependencies> {
  try {
    if (AnalyticsConstants.ANALYTICS_ENABLE_BEACON && typeof window?.s?.tl === 'function') {
      // See https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/tl-method.html?lang=en
      yield* call(window.s.tl, true, 'o', action.payload.linkName);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* analyticsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(AnalyticsActions.linkActivated, linkActivatedSaga);
}
