import styled from 'styled-components';

export const StyledColumnCustomizerPanel = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  gap: ${(props): string => props.theme.spacing.lg};

  .column-customizer-panel__header {
    flex-grow: 0;
  }

  .column-customizer-panel__title {
    ${(props): string => props.theme.fontStyles.redesign.heading2};
    text-transform: uppercase;
  }

  .column-customizer-panel__select-all-checkbox {
    padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.sm};
  }

  .column-customizer-panel__checkbox-label {
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
    line-height: ${(props): string => props.theme.lineHeights.redesign.lg};

    // Fix font baseline
    margin-top: 2px;
    margin-bottom: -2px;
  }

  .column-customizer-panel__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${(props): string => props.theme.spacing.xs};
    overflow: auto;
  }

  .column-customizer-panel__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 0;
  }

  .column-customizer-panel__cancel-button,
  .column-customizer-panel__save-button {
    &.ant-btn.button-base.button__size-m {
      ${(props): string => props.theme.fontStyles.redesign.base.delete};
    }
  }
`;
