/* eslint-disable max-len */
import { configureStore } from '@reduxjs/toolkit';
import { DevToolsEnhancerOptions as DevToolsOptions } from '@reduxjs/toolkit/src/devtoolsExtension';
import createSagaMiddleware from 'redux-saga';
import { combineReducers, Store as IStore } from 'redux';
import { ICreateReduxStoreOptions } from './interfaces/StateManagement.types';
import { reducers } from './reducers';
import { sagas } from './sagas';
import { isEnvVarEnabled } from 'lib/utils/env-variables/isEnvVarEnabled';

export class StateManagement {
  public static createReduxStore = ({ state = {}, dependencies }: ICreateReduxStoreOptions): IStore => {
    let devToolsConfig: boolean | DevToolsOptions = false;
    const sagaMiddleware = createSagaMiddleware({
      context: {
        dependencies,
      },
    });

    const rootReducer = combineReducers(reducers);

    if (isEnvVarEnabled(process.env.REACT_APP_ENABLE_REDUX_DEV_TOOL)) {
      devToolsConfig = { trace: true, traceLimit: 25, maxAge: 999 };

      if (isEnvVarEnabled(process.env.REACT_APP_REDUX_DEV_TOOL_FILTER_OUT_ANALYTICS)) {
        // Strings will be interpreted as regex
        // @See https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#actionsblacklist--actionswhitelist
        devToolsConfig.actionsDenylist = ['analytics/'];
      }
    }

    const store = configureStore({
      reducer: rootReducer,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: {
            // Ignore these field paths (from MachineDetailsActions.ADD_MACHINE_ATTACHMENT_REQUEST action) in all actions
            ignoredActionPaths: ['payload.file.lastModifiedDate', 'payload.file.originFileObj'],
          },
        }).concat(sagaMiddleware),
      devTools: devToolsConfig,
      preloadedState: state,
    });

    sagaMiddleware.run(sagas);

    return store;
  };
}
