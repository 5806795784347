/* Identifiers are defined by OpenID Connect */
/* eslint-disable camelcase */

import { Optional } from '../../../../lib/types/Optional';

export enum OidcTokenType {
  ACCESS = 'access',
  ID = 'id',
}

export interface JwtTokenHeader {
  [key: string]: any;
  alg: string;
  kid: string;
}

export interface ICognitoIdTokenPayload {
  [id: string]: any;
  at_hash: string;
  sub: string;
  'cognito:groups': string[];
  email_verified: boolean;
  iss: string;
  'cognito:username': string;
  nonce: string;
  origin_jti: string;
  aud: string;
  identities: [
    {
      userId: string;
      providerName: string;
      providerType: string;
      issuer: Optional<string>;
      primary: string;
      dateCreated: string;
    }
  ];
  token_use: 'id';
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  email: string;
}

export interface IIdTokenCustomUserAttributes {
  'custom:customerId'?: string;
  'custom:title'?: string;
  'custom:displayName'?: string;
  'custom:phoneNumber'?: string;
  'custom:preferredLanguage'?: string;
  'custom:country'?: string;
}

export type IIdTokenPayload = ICognitoIdTokenPayload & IIdTokenCustomUserAttributes;

export interface IAccessTokenPayload {
  [id: string]: any;
  sub: string;
  'cognito:groups': string[];
  iss: string;
  version: 2;
  client_id: string;
  origin_jti: string;
  token_use: 'access';
  scope: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  username: string;
}

export interface IAuthenticationTokens {
  jwt: {
    idToken: string;
    accessToken: string;
    refreshToken: string;
  };
  decoded: {
    idToken: IIdTokenPayload;
    accessToken: IAccessTokenPayload;
  };
}
