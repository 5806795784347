import { useEffect, useState } from 'react';
import { BrowserHistory } from 'history';
import { Store as IStore } from 'redux';
import { useRoutes } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ConfigProvider as AntdConfigProvider, Spin } from 'antd';
import dayjs from 'dayjs';
import { AnalyticsDataLayerRenderer } from './app/cross-cutting-concerns/analytics/components/AnalyticsDataLayerRenderer/AnalyticsDataLayerRenderer';
import { AppConfigurationContext } from './app/cross-cutting-concerns/configuration/AppConfigurationContext';
import { DependenciesContext } from './app/cross-cutting-concerns/dependency-injection/DependenciesContext';
import { IDependencies } from './app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { IRoute } from './app/cross-cutting-concerns/routing/interfaces/Routing.types';
import { TranslationsActions } from './app/cross-cutting-concerns/translations/state/translationsSlice';
import { appConfig } from './config/app-config';
import { GlobalStyles } from './config/global-styles';
import { BrowserRouter } from './app/cross-cutting-concerns/routing/components/BrowserRouter/BrowserRouter';
import { Routing } from './app/cross-cutting-concerns/routing/Routing';
import './App.css';
import { LoadingIndicator } from './lib/components/LoadingIndicator/LoadingIndicator';
import { IDayjsIntl } from 'app/cross-cutting-concerns/translations/interfaces/Translations.types';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { UsersnapProvider } from 'app/cross-cutting-concerns/usersnap/UsersnapContext';
import { RoutesContext } from 'app/cross-cutting-concerns/routing/RoutesContext';
import { AsyncJobHandler } from 'app/cross-cutting-concerns/async-job/components/AsyncJobHandler/AsyncJobHandler';
import { Styling } from 'app/cross-cutting-concerns/styling/Styling';

interface AppProps {
  store: IStore;
  styling: Styling;
  routing: Routing;
  history: BrowserHistory;
  dependencies: IDependencies;
}

const RouteRenderer = ({ routes }: { routes: IRoute[] }): JSX.Element | null => {
  const routerElements = useRoutes(routes);
  return routerElements;
};

export const App = ({ store, styling, history, routing, dependencies }: AppProps): JSX.Element => {
  const { i18n, t } = useTranslation();
  const [locale, setLocale] = useState(Translations.getAntdLocale());
  const [userSnapLocale, setUserSnapLocale] = useState(Translations.getUserSnapLocaleSupported());
  const routes = routing.getAllRoutesWithAppLayout();
  const styledComponentsTheme = styling.getStyledComponentsTheme();
  const antDesignThemeConfig = styling.getAntDesignThemeConfig();

  Spin.setDefaultIndicator(<LoadingIndicator />);

  useEffect(() => {
    if (i18n.language) {
      const dayjsIntl: IDayjsIntl = t('dayjsIntl', { returnObjects: true });
      // Can't not override partially locale config(dayjsIntl) but use the plugin updateLocale
      // https://day.js.org/docs/en/customization/customization
      dayjs.locale(dayjsIntl.locale);
      dayjs.updateLocale(dayjsIntl.locale, dayjsIntl.localeSpecification);
      const antdLocale = Translations.getAntdLocale();
      const userSnapLocaleSupported = Translations.getUserSnapLocaleSupported();
      setUserSnapLocale(userSnapLocaleSupported);
      setLocale(antdLocale);
      store.dispatch(
        TranslationsActions.setLanguage({
          language: i18n.language,
        })
      );
    }
  }, [i18n.language, store, t]);

  return (
    <StoreProvider store={store}>
      <AppConfigurationContext.Provider value={appConfig}>
        <DependenciesContext.Provider value={dependencies}>
          <RoutesContext.Provider value={routes}>
            <ThemeProvider theme={styledComponentsTheme}>
              <AntdConfigProvider locale={locale} theme={antDesignThemeConfig}>
                <UsersnapProvider initParams={{ locale: userSnapLocale, nativeScreenshot: true }}>
                  <BrowserRouter history={history}>
                    <AnalyticsDataLayerRenderer>
                      <AsyncJobHandler>
                        <RouteRenderer routes={routes} />
                      </AsyncJobHandler>
                    </AnalyticsDataLayerRenderer>
                  </BrowserRouter>
                  <GlobalStyles />
                </UsersnapProvider>
              </AntdConfigProvider>
            </ThemeProvider>
          </RoutesContext.Provider>
        </DependenciesContext.Provider>
      </AppConfigurationContext.Provider>
    </StoreProvider>
  );
};

export default App;
