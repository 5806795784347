import { IRoute } from '../interfaces/Routing.types';

export class PublicRouteWithFeatureFlagError extends Error {
  constructor(route: IRoute) {
    super(
      // eslint-disable-next-line max-len
      `Public route with path ${route.path} has a feature flag defined. Be aware that public routes cannot be feature flagged and you need to remove it. This is because in order to determine the feature flag configuration a users groups need to be known and thus a login is needed.`
    );
  }
}
