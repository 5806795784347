import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IPermission } from '../../interfaces/Authentication.types';
import { selectPermissions } from '../../state/authenticationSelectors';
import { isUserPermitted } from '../../utils/isUserPermitted';

export interface PermissionGuardProps {
  requiredPermissions: IPermission[];
  fallback?: React.ReactNode;
  children?: React.ReactNode;
}

export const PermissionGuard: React.FC<PermissionGuardProps> = ({ requiredPermissions, fallback = null, children }) => {
  const userPermissions = useSelector(selectPermissions);

  const isPermitted = useMemo(
    () => isUserPermitted(userPermissions, requiredPermissions),
    [userPermissions, requiredPermissions]
  );

  return isPermitted ? <>{children}</> : <>{fallback}</>;
};
