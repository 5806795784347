import React, { useEffect, useState, useContext } from 'react';
import { IUsersnapAPI, IOnUsersnapAPIParam } from './interfaces/IUsersnap';

const UsersnapContext = React.createContext<IUsersnapAPI>(null);

interface UsersnapProviderProps {
  children: React.ReactNode;
  initParams: {
    nativeScreenshot: boolean;
    locale?: string;
  };
}

export const UsersnapProvider = ({ children, initParams }: UsersnapProviderProps): JSX.Element => {
  const [usersnapApi, setUsersnapApi] = useState<IUsersnapAPI>(null);

  useEffect(() => {
    let usersnapAPI: IUsersnapAPI = null;

    function onUsersnapCXLoad(api: IOnUsersnapAPIParam): void {
      api.init(initParams);
      usersnapAPI = api;
      setUsersnapApi(api);
    }

    (window as unknown as Record<string, unknown>).onUsersnapCXLoad = onUsersnapCXLoad;

    function cleanup(): void {
      if (usersnapAPI) {
        usersnapAPI.destroy();
      }
      // Clean up the effect by removing the attached function from window on unmount
      (window as any).onUsersnapCXLoad = null;
    }

    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UsersnapContext.Provider value={usersnapApi}>{children}</UsersnapContext.Provider>;
};

// Link reference: https://github.com/usersnap/public/blob/master/react-js/src/examples/WidgetApiEvents.js
export const useUsersnapApi = (): IUsersnapAPI => useContext(UsersnapContext);
