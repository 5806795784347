import styled from 'styled-components';
import { Layout } from 'antd';

const { Header } = Layout;

export const StyledAppHeader = styled(Header)`
  background-color: #fff;
  height: auto;
  padding: 0;

  .app-header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 94px;
    padding: 0px 72px 0px 20px;
    border-bottom: 12px solid;
    border-color: ${(props): string => props.theme.colors.darkGrey};
  }

  .app-header__links {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 100%;
    overflow: hidden;

    & > * {
      margin-left: 18px;
    }
  }

  .app-header__link {
    ${(props): string => props.theme.fontStyles.buttonM};
  }

  .app-header__logout {
    margin-left: auto;
  }
`;
