import { IState } from '../../state-management/interfaces/IState';
import { Nullable } from 'lib/types/Nullable';

export class TranslationsSelectors {
  public static selectPrimaryLanguage = (state: IState): Nullable<string> =>
    state['cross-cutting-concerns']?.translations?.language.primary;

  public static selectLanguageWithRegion = (state: IState): Nullable<string> =>
    state['cross-cutting-concerns']?.translations?.language.withRegion;
}
