import { ReactComponent as LetterUnread } from '../../../assets/images/letter-unread.svg';

export const ToastIcon = (iconName?: string): JSX.Element | null => {
  switch (iconName) {
    case 'letter-unread':
      return <LetterUnread />;

    // Default icon base on ant design
    default:
      return null;
  }
};
