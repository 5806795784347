import styled from 'styled-components';

export const StyledColumnCustomizerItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${(props): string => props.theme.colors.grey200};
  padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.sm};
  gap: ${(props): string => props.theme.spacing.xs};

  .column-customizer-item__label {
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
    line-height: ${(props): string => props.theme.lineHeights.redesign.lg};

    // Fix font baseline
    margin-top: 2px;
    margin-bottom: -2px;
  }
`;
