import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IAnalyticsMachineAttributes } from '../interfaces/Analytics.types';
import { AnalyticsActions } from '../state/analyticsSlice';

/**
 * Hook to set the machine attributes for analytics
 */
export function useAnalyticsSetMachineAttributes(machineAttributes: Partial<IAnalyticsMachineAttributes>): void {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AnalyticsActions.setMachineAttributes(machineAttributes));

    return () => {
      dispatch(AnalyticsActions.setMachineAttributes({}));
    };
  }, [dispatch, machineAttributes]);
}
