import { useTranslation } from 'react-i18next';
import { StyledRobotRoutine } from './RobotRoutine.styles';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { StepsProgress } from 'lib/components/Progress/StepsProgress/StepsProgress';
import { BarProgress } from 'lib/components/Progress/BarProgress/BarProgress';
import {
  MachineConnectionStatus,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { RobotUtils } from 'app/utils/robot/RobotUtils';

export const RobotRoutine = ({ robot }: { robot: Machine }): JSX.Element => {
  const { t } = useTranslation();

  const isRobotCleaning =
    robot.robotStatus === RobotStatus.Autonomous && robot.connectionStatus === MachineConnectionStatus.Online;

  const isRobotHaveRoutine = robot?.latestRoutine?.data;

  const routineName = robot?.latestRoutine?.data?.name;
  const currentRepeat = robot?.latestRoutine?.data?.currentRepeat || 0;
  const numberOfRepeats = robot?.latestRoutine?.data?.numberOfRepeats || 0;

  const getFormatSteps = (): string => String(currentRepeat).concat('/').concat(String(numberOfRepeats));

  if (!isRobotCleaning || !isRobotHaveRoutine) return <></>;

  return (
    <StyledRobotRoutine>
      <p className="robot-routine__title">{routineName || t('robotDashboard.cleaningRoute.label.unNamedRoutine')}</p>
      <div className="robot-routine__body">
        {numberOfRepeats <= 50 ? (
          <StepsProgress
            className="robot-routine__routine"
            status="active"
            steps={numberOfRepeats}
            percent={RobotUtils.getPercentageData({
              data: currentRepeat,
              total: numberOfRepeats,
            })}
            format={getFormatSteps}
          />
        ) : (
          <BarProgress
            className="robot-routine__routine"
            percent={RobotUtils.getPercentageData({
              data: currentRepeat,
              total: numberOfRepeats,
            })}
            size="small"
            status="active"
            format={getFormatSteps}
          />
        )}
      </div>
    </StyledRobotRoutine>
  );
};
