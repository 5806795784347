export interface IDayjsIntl {
  locale: string;
  dateFormatPattern: string;
  localeSpecification: Partial<ILocale>;
}

export enum Country {
  AUSTRALIA = 'AUSTRALIA',
  AUSTRIA = 'AUSTRIA',
  BELGIUM = 'BELGIUM',
  CZECH_REPUBLIC = 'CZECH_REPUBLIC',
  DENMARK = 'DENMARK',
  FRANCE = 'FRANCE',
  GERMANY = 'GERMANY',
  ITALY = 'ITALY',
  NETHERLANDS = 'NETHERLANDS',
  NORWAY = 'NORWAY',
  SWEDEN = 'SWEDEN',
  SWITZERLAND = 'SWITZERLAND',
  UNITED_KINGDOM = 'UNITED_KINGDOM',
  USA = 'USA',
  SPAIN = 'SPAIN',
  JAPAN = 'JAPAN',
  PORTUGAL = 'PORTUGAL',
  KOREA = 'KOREA',
}
