export enum ConfigurationSet {
  HIDDEN = 'HIDDEN',
  STABLE = 'STABLE',
  NEXT_EM_VERSION_FEATURES = 'NEXT_EM_VERSION_FEATURES',
  ROBOTICS_FEATURES = 'ROBOTICS_FEATURES',
}

export enum AwsAppConfigFeatureFlags {
  ENABLE_NEXT_EM_VERSION_FEATURES = 'enableNextEMVersionFeatures',
  ENABLE_ROBOTICS_FEATURES = 'enableRoboticsFeatures',
}

export type AwsAppConfigData = {
  [key in AwsAppConfigFeatureFlags]: {
    enabled: boolean;
  };
};
