import {
  BrowserStorageAdapter,
  BrowserStorageGetOptions,
  BrowserStorageSetOptions,
} from '../interface/BrowserStorage.types';

export class LocalStorageAdapter implements BrowserStorageAdapter {
  public get(key: string, _options?: BrowserStorageGetOptions): string | null {
    if (window.localStorage === undefined) {
      throw new Error('LocalStorageAdapter only works in the browser.');
    }

    const value = window.localStorage.getItem(key);

    if (value === null) {
      throw new Error(`No key "${key}" in local storage.`);
    }

    return value;
  }

  public set(key: string, value: string, _options?: BrowserStorageSetOptions): void {
    if (window.localStorage === undefined) {
      throw new Error('LocalStorageAdapter only works in the browser.');
    }

    window.localStorage.setItem(key, value);
  }
}
