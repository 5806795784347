import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RoutePaths } from '../../../../../config/route-paths';
import { FeatureFlagSelectors } from '../../state/featureFlagSelectors';
import { IRoute } from '../../../routing/interfaces/Routing.types';

export interface FeatureFlagGuardProps {
  route: IRoute;
  children?: React.ReactNode;
}

export const FeatureFlagGuard: React.FC<FeatureFlagGuardProps> = ({ route, children }) => {
  const featureFlagConfig = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const isRouteDisabledByFeatureFlag = route.featureFlag !== undefined && !featureFlagConfig[route.featureFlag];

  if (isRouteDisabledByFeatureFlag) {
    return <Navigate to={RoutePaths.ROOT} />;
  }

  return <>{children}</>;
};
