import { call, getContext, put, takeLatest } from 'typed-redux-saga';
import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { IMachineClassificationAvailabilityConfig } from '../../../modules/machine-inventory/interfaces/Machine.types';
import { IDependencies } from '../../dependency-injection/interfaces/IDependencies';
import { Optional } from '../../../../lib/types/Optional';
import { CognitoError } from '../errors/CognitoError';
import { IAuthenticationTokens } from '../interfaces/IAuthenticationTokens';
import { isAuthenticationTokenObject } from '../utils/isAuthenticationTokenObject';
import { RoutePaths } from '../../../../config/route-paths';
import {
  AuthenticationActions,
  ICheckAuthenticationErrorAction,
  ICheckAuthenticationAuthenticatedAction,
  ISignInErrorAction,
  ICheckAuthenticationNotAuthenticatedAction,
  ISignOutErrorAction,
  ISignOutSuccessAction,
  IGetClassificationAvailabilitySuccessAction,
  IGetClassificationAvailabilityErrorAction,
} from './authenticationActions';

export function* checkAuthenticationSaga(): Generator<
  | GetContextEffect
  | CallEffect
  | PutEffect<ICheckAuthenticationAuthenticatedAction>
  | PutEffect<ICheckAuthenticationNotAuthenticatedAction>
  | PutEffect<ICheckAuthenticationErrorAction>,
  void,
  IDependencies
> {
  const { authenticationService, history } = yield* getContext<IDependencies>('dependencies');

  try {
    const tokens = yield* call(authenticationService.checkAuthentication);
    const permissions = yield* call(authenticationService.getPermissions);

    if (isAuthenticationTokenObject(tokens)) {
      yield* put(
        AuthenticationActions.checkAuthenticationAuthenticated({
          tokens,
          permissions,
        })
      );
    } else {
      yield* put(AuthenticationActions.checkAuthenticationNotAuthenticated());
      history.push(RoutePaths.LOGIN);
    }
  } catch (error) {
    console.error(error);

    yield* put(
      AuthenticationActions.checkAuthenticationError({
        error,
      })
    );

    if ((error as Error)?.message === CognitoError.REFRESH_TOKEN_EXPIRED) {
      yield* call(authenticationService.signOut);
      history.push(RoutePaths.LOGIN);
    }
  }
}

export function* signInSaga(): Generator<
  GetContextEffect | CallEffect<void> | PutEffect<ISignInErrorAction>,
  void,
  IDependencies
> {
  const { authenticationService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* call(authenticationService.signIn);
  } catch (error) {
    console.error(error);

    yield* put(
      AuthenticationActions.signInError({
        error,
      })
    );
  }
}

export function* signOutSaga(): Generator<
  | GetContextEffect
  | CallEffect<void>
  | CallEffect<Optional<IAuthenticationTokens>>
  | PutEffect<ISignOutSuccessAction>
  | PutEffect<ISignOutErrorAction>,
  void,
  IDependencies
> {
  const { authenticationService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* call(authenticationService.signOut);

    const tokens = yield* call(authenticationService.getTokens);

    yield* put(
      AuthenticationActions.signOutSuccess({
        tokens,
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      AuthenticationActions.signOutError({
        error,
      })
    );
  }
}

function* getClassificationAvailabilitySaga(): Generator<
  | GetContextEffect
  | CallEffect<void>
  | CallEffect<IMachineClassificationAvailabilityConfig>
  | PutEffect<IGetClassificationAvailabilitySuccessAction>
  | PutEffect<IGetClassificationAvailabilityErrorAction>,
  void,
  IDependencies
> {
  try {
    const { machineService } = yield* getContext<IDependencies>('dependencies');
    const classificationAvailabilityConfig = yield* call(machineService.getClassificationAvailabilityConfig);

    machineService.persistClassificationAvailabilityConfig(classificationAvailabilityConfig);

    yield* put(
      AuthenticationActions.getClassificationAvailabilitySuccess({
        ...classificationAvailabilityConfig,
      })
    );
  } catch (error) {
    console.error(error);
    yield* put(AuthenticationActions.getClassificationAvailabilityError());
  }
}

export function* authenticationSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(AuthenticationActions.CHECK_AUTHENTICATION, checkAuthenticationSaga);
  yield* takeLatest(AuthenticationActions.SIGN_IN, signInSaga);
  yield* takeLatest(AuthenticationActions.SIGN_OUT, signOutSaga);
  yield* takeLatest(AuthenticationActions.GET_CLASSIFICATION_AVAILABILITY_REQUEST, getClassificationAvailabilitySaga);
}
