import styled from 'styled-components';

export const StyledUnauthorized = styled.div`
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('unauthorized')}

  .unauthorized__header {
    display: flex;
    justify-content: center;
    background-color: ${(props): string => props.theme.colors.primary};
    padding: 16px;
  }

  .unauthorized__header-name {
    text-transform: uppercase;
    padding-left: 24px;
    ${(props): string => props.theme.fontStyles.heading1}
  }

  .unauthorized__body {
    padding: 16px;
  }

  // Page has no sidebar, so define a fixed container class without breakpoint and left margin
  .unauthorized__container--narrow {
    max-width: ${(props): number => props.theme.constants.narrowContainer.maxWidth}px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 ${(props): number => props.theme.constants.baseGap}px;
  }

  .unauthorized__banner {
    padding-top: 24px;
    padding-bottom: 64px;
  }

  .unauthorized__banner-desc {
    ${(props): string => props.theme.fontStyles.heading3}
  }

  .unauthorized__content-list {
    ${(props): string => props.theme.fontStyles.p1.news}
    font-size: 1em;
    line-height: 150%;
  }

  .unauthorized__content-access-desc {
    ${(props): string => props.theme.fontStyles.p1.news}
    font-size: 1.5em;
    line-height: 150%;
    padding-top: 24px;
  }
`;
