import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../../../../lib/types/Nullable';
import { Translations } from '../Translations';
import { ISetLanguagePayload } from './translationsActions.types';

export interface ITranslationsState {
  language: {
    // https://en.wikipedia.org/wiki/IETF_language_tag#Syntax_of_language_tags
    primary: Nullable<string>;
    withRegion: Nullable<string>;
  };
}

export const initialState: ITranslationsState = {
  language: {
    primary: null,
    withRegion: null,
  },
};

const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<ISetLanguagePayload>) => {
      state.language.primary = Translations.getPrimaryLanguageSubtag(action.payload.language);
      state.language.withRegion = action.payload.language;
    },
  },
});

export const TranslationsActions = translationsSlice.actions;
export const translationsReducer = translationsSlice.reducer;
