import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import React from 'react';
import { Checkbox } from '../../../../lib/components/Checkbox/Checkbox';
import { StyledColumnCustomizerItem } from './ColumnCustomizerItem.styles';

export interface ColumnCustomizerItemProps {
  key: string;
  label: string;
  value: string;
  checked: boolean;
  onChange: (e: CheckboxChangeEvent, value: string) => void;
}

export const ColumnCustomizerItem: React.FunctionComponent<ColumnCustomizerItemProps> = ({
  label,
  value,
  checked,
  onChange,
}): React.JSX.Element => {
  const handleChange = (e: CheckboxChangeEvent): void => {
    onChange(e, value);
  };

  return (
    <StyledColumnCustomizerItem className="column-customizer-item">
      <Checkbox className="column-customizer-item__checkbox" checked={checked} onChange={handleChange}>
        <div className="column-customizer-item__label">{label}</div>
      </Checkbox>
    </StyledColumnCustomizerItem>
  );
};
