import { IState } from '../../state-management/interfaces/IState';
import { IAnalyticsApplication, IAnalyticsDigitalData } from '../interfaces/Analytics.types';

export class AnalyticsSelectors {
  public static selectDigitalData = (state: IState): IAnalyticsDigitalData =>
    state['cross-cutting-concerns']?.analytics?.digitalData;

  public static selectApplication = (state: IState): IAnalyticsApplication =>
    state['cross-cutting-concerns']?.analytics?.digitalData.application;
}
