import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Optional } from '../../../../../lib/types/Optional';
import { selectUserGroups } from '../../../authentication/state/authenticationSelectors';
import { TranslationsSelectors } from '../../../translations/state/translationsSelectors';
import { AnalyticsSelectors } from '../../state/analyticsSelectors';
import { AnalyticsActions } from '../../state/analyticsSlice';
import { CUSTOMER_GROUP_PREFIX } from 'app/cross-cutting-concerns/authentication/constants';

export interface AnalyticsDataLayerRendererProps {
  children: React.ReactNode;
}
export const AnalyticsDataLayerRenderer = ({ children }: AnalyticsDataLayerRendererProps): JSX.Element => {
  const dispatch = useDispatch();
  const digitalData = useSelector(AnalyticsSelectors.selectDigitalData);
  const primaryLanguage = useSelector(TranslationsSelectors.selectPrimaryLanguage);
  const location = useLocation();
  const userGroups = useSelector(selectUserGroups);
  const customerGroup = userGroups.find(group => group.startsWith(CUSTOMER_GROUP_PREFIX));
  let customerId: Optional<string>;

  if (customerGroup) {
    customerId = customerGroup.replace(CUSTOMER_GROUP_PREFIX, '');
  }

  useEffect(() => {
    if (customerId) {
      dispatch(AnalyticsActions.setCustomerId(customerId));
    }
  }, [customerId, dispatch]);

  useEffect(() => {
    window.digitalData = {
      ...digitalData,
      application: {
        ...digitalData.application,
        applicationInfo: {
          ...digitalData.application.applicationInfo,
          language: primaryLanguage,
        },
      },
      attribute: {
        ...digitalData.attribute,
        customer: {
          ...digitalData.attribute.customer,
          id: customerId,
        },
        filter: digitalData.attribute.filter.join('|'),
      },
    };
  }, [customerId, digitalData, location.pathname, primaryLanguage]);

  return <>{children}</>;
};
