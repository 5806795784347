import { Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

export const StyledSideNav = styled(Sider)`
  &.ant-layout-sider {
    .side-bar__footer-menu {
      position: absolute;
      bottom: 43.8px;
      left: 0;
      width: 100%;
    }
    background-color: ${(props): string => props.theme.colors.darkGrey};
    text-transform: uppercase;
    transition-property: opacity, width, min-width, max-width, flex-basis, transform;
    transition-duration: 0.4s;
    transition-timing-function: ${(props): string => props.theme.transitionTimingFunctions.easeOutBack};
    will-change: width;

    .ant-menu {
      background-color: ${(props): string => props.theme.colors.darkGrey};

      &.ant-menu-vertical {
        border-right: none;
      }

      & .ant-menu-item {
        display: flex;
        align-items: center;
        margin: 0;
        background-color: ${(props): string => props.theme.colors.darkGrey};
        height: 40px;
        width: 100%;
        padding-block-start: ${(props): string => props.theme.spacing.xs};
        padding-block-end: ${(props): string => props.theme.spacing.xs};

        :focus {
          border: 2px solid ${(props): string => props.theme.colors.focusBorder};
        }

        &.ant-menu-item-active {
          background-color: ${(props): string => props.theme.colors.darkGrey};
        }

        & a {
          color: ${(props): string => props.theme.colors.white};
          font-size: 11px;
          display: block;
          ${(props): string => props.theme.mixins.truncateText}
        }

        & .ant-menu-item-icon {
          fill: ${(props): string => props.theme.colors.white};
          width: 22px;
          height: 20px;
          flex-shrink: 0;

          & + span {
            margin-left: ${(props): string => props.theme.spacing.xs};
            overflow: hidden;
          }
        }

        &.ant-menu-item-selected {
          background-color: ${(props): string => props.theme.colors.black};
          font-weight: bold;

          & a {
            color: ${(props): string => props.theme.colors.primary};
          }

          & .ant-menu-item-icon path,
          & .ant-menu-item-icon rect {
            fill: ${(props): string => props.theme.colors.primary} !important;
          }

          & .ant-menu-item-icon {
            & + span {
              color: ${(props): string => props.theme.colors.primary};
            }
          }
        }

        & .ant-menu-title-content {
          font-family: ${(props): string => props.theme.fonts.heading3};
          width: 100%;
          opacity: 1;
          will-change: opacity;
        }
      }
    }
  }

  .ant-layout-sider-children {
    padding-bottom: 61px;
  }

  .side-nav__toggle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${(props): number => props.theme.constants.sideBar.width.collapsed}px;
    padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.base};
    color: ${(props): string => props.theme.colors.white};
    background-color: transparent;

    &:hover {
      color: ${(props): string => props.theme.colors.white} !important;
    }

    svg {
      transform: rotate(-180deg);
      transform-origin: center;
      will-change: transform;
      transition: transform 0.4s ${(props): string => props.theme.transitionTimingFunctions.easeOutBack};
    }
  }

  &.side-nav--collapsed.ant-layout-sider {
    .ant-menu {
      .ant-menu-item {
        .ant-menu-title-content {
          opacity: 0;
        }
      }
    }

    .side-nav__toggle {
      svg {
        transform: rotate(0deg);
      }
    }
  }
`;
