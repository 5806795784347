import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';

export const selectIsMachineDetailsDrawerVisible = (state: IState): boolean =>
  state['cross-cutting-concerns'].drawers.machineDetails.visible;

export const selectMachineDetailsDrawerMachineId = (state: IState): string | null =>
  state['cross-cutting-concerns'].drawers.machineDetails.machineId;

export const selectIsOperatorDetailsDrawerVisible = (state: IState): boolean =>
  state['cross-cutting-concerns'].drawers.operatorDetails.visible;

export const selectIsSiteDetailsDrawerVisible = (state: IState): boolean =>
  state['cross-cutting-concerns'].drawers.siteDetails.visible;

export const selectSiteDetailsDrawerSiteId = (state: IState): string | null =>
  state['cross-cutting-concerns'].drawers.siteDetails.siteId;
