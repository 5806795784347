import styled from 'styled-components';

export const StyledLogout = styled.div`
  .logout__logout-button {
    .logout__logout-button-title {
      ${(props): string => props.theme.fontStyles.redesign.base.strong}
      font-weight: ${(props): number => props.theme.fontWeights.p0};
    }
  }

  .logout__logout-button-title {
    display: flex;
    align-items: center;
  }

  .logout__logout-button-text {
    padding-right: ${(props): string => props.theme.spacing.xs};
  }
`;
