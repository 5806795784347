import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectIsSiteDetailsDrawerVisible, selectSiteDetailsDrawerSiteId } from '../../state/drawersSelectors';
import { DrawersActions } from '../../state/drawersSlice';
import { SiteDetailsPanel } from 'app/modules/site-management/site-details-panel/components/SiteDetailsPanel/SiteDetailsPanel';
import { Drawer } from 'lib/components/Drawer/Drawer';
import { RoutePaths } from 'config/route-paths';

export const SiteDetailsDrawer = (): JSX.Element => {
  const dispatch = useDispatch();
  const isVisible = useSelector(selectIsSiteDetailsDrawerVisible);
  const siteId = useSelector(selectSiteDetailsDrawerSiteId);
  const location = useLocation();
  const navigate = useNavigate();

  if (!siteId) return <></>;

  return (
    <Drawer
      className="site-details-drawer"
      size="medium"
      open={isVisible}
      mask={false}
      onClose={(): void => {
        dispatch(DrawersActions.hideSiteDetailsDrawer());
        if (location.pathname.startsWith(RoutePaths.SITES)) {
          navigate(RoutePaths.SITES);
        }
      }}
    >
      <SiteDetailsPanel id={siteId} />
    </Drawer>
  );
};
