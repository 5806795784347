import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash-es/merge';
import { analyticsApplicationInfo } from '../../../../config/analyticsApplicationInfo';
import {
  IAnalyticsDigitalData,
  IAnalyticsFilter,
  IAnalyticsForm,
  IAnalyticsMachineAttributes,
  IAnalyticsPageInfo,
  IAnalyticsProductInfo,
  IAnalyticsSiteAttributes,
  IAnalyticsUserAttributes,
} from '../interfaces/Analytics.types';
import { IAnalyticsLinkActivatedAction } from './analyticsActions.types';
import { Optional } from 'lib/types/Optional';

export interface IAnalyticsState {
  digitalData: IAnalyticsDigitalData;
}

export const initialState: IAnalyticsState = {
  digitalData: {
    application: {
      applicationInfo: {
        ...analyticsApplicationInfo,
      },
    },
    page: {
      pageInfo: {
        pageName: null,
        pathPattern: null,
        pageId: null,
      },
    },
    product: {
      productInfo: {
        productName: null,
        productMaterialNo: null,
        productSerialNo: null,
      },
    },
    link: {
      linkName: null,
    },
    attribute: {
      filter: [],
      search: {
        term: null,
      },
      form: {
        name: null,
        fields: null,
      },
      customer: {
        id: null,
      },
      machines: {
        count: null,
      },
      sites: {
        count: null,
      },
      users: {
        countAuthorized: null,
        countOutstandingInvitations: null,
      },
    },
  },
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setCustomerId: (state, action: PayloadAction<Optional<string>>) => {
      state.digitalData.attribute.customer.id = action.payload;
      return state;
    },
    setPageInfo: (state, action: PayloadAction<IAnalyticsPageInfo>) => {
      state.digitalData.page.pageInfo = action.payload;
      return state;
    },
    // Needs a specific action because it is required that user attributes are set at the same time as the page info
    setUserListPageInfo: (
      state,
      action: PayloadAction<{ pageInfo: IAnalyticsPageInfo; userAttributes: Partial<IAnalyticsUserAttributes> }>
    ) => {
      state.digitalData.page.pageInfo = action.payload.pageInfo;
      state.digitalData.attribute.users = action.payload.userAttributes;
      return state;
    },
    setProductInfo: (state, action: PayloadAction<IAnalyticsProductInfo>) => {
      state.digitalData.product.productInfo = action.payload;
      return state;
    },
    setFilter: (state, action: PayloadAction<IAnalyticsFilter[]>) => {
      state.digitalData.attribute.filter = action.payload;
      return state;
    },
    setSearch: (state, action: PayloadAction<Optional<string>>) => {
      state.digitalData.attribute.search.term = action.payload;
      return state;
    },
    setForm: (state, action: PayloadAction<IAnalyticsForm>) => {
      state.digitalData.attribute.form = action.payload;
      return state;
    },
    clearForm: state => {
      state.digitalData.attribute.form = {
        name: null,
        fields: null,
      };
      return state;
    },
    setMachineAttributes: (state, action: PayloadAction<Partial<IAnalyticsMachineAttributes>>) => {
      state.digitalData.attribute.machines = action.payload;
      return state;
    },
    setSiteAttributes: (state, action: PayloadAction<Partial<IAnalyticsSiteAttributes>>) => {
      state.digitalData.attribute.sites = action.payload;
      return state;
    },
    setUserAttributes: (state, action: PayloadAction<Partial<IAnalyticsUserAttributes>>) => {
      state.digitalData.attribute.users = action.payload;
      return state;
    },
    addUserAttributes: (state, action: PayloadAction<Partial<IAnalyticsUserAttributes>>) => {
      merge(state.digitalData.attribute.users, action.payload);
      return state;
    },
    linkActivated: (state, action: IAnalyticsLinkActivatedAction) => {
      state.digitalData.link = action.payload;
      return state;
    },
    clearLinkName: state => {
      state.digitalData.link = {
        linkName: null,
      };
      return state;
    },
  },
});

export const AnalyticsActions = analyticsSlice.actions;
export const analyticsReducer = analyticsSlice.reducer;
