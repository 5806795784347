import { isNil } from 'lodash-es';
import { SOFTWARE_VERSION_CONNECT_VNC_MAJOR, SOFTWARE_VERSION_CONNECT_VNC_MINOR } from 'config/constants';
import { Optional } from 'lib/types/Optional';

export class VncUtils {
  public static isRemoteControllable = (version: Optional<string>): boolean => {
    if (!version) {
      return false;
    }

    const regex = /^(.*?)?(\d+)\.(\d+)\.(\d+)(.*)$/;
    const matches = version.match(regex);
    let majorVersion = SOFTWARE_VERSION_CONNECT_VNC_MAJOR;

    if (matches) {
      const [, _prefix, major, _minor, _patch, _suffix] = matches;
      majorVersion = Number(major);
    }

    return Number.isNaN(majorVersion) ? true : majorVersion >= SOFTWARE_VERSION_CONNECT_VNC_MAJOR;
  };

  public static getVncUrl = ({ token, region }: { token: Optional<string>; region: Optional<string> }): string => {
    if (isNil(token)) {
      return '';
    }

    if (isNil(region)) {
      return '';
    }

    return `wss://data.tunneling.iot.${region}.amazonaws.com:443/tunnel?local-proxy-mode=source&access-token=${token}`;
  };

  public static isUsingVncV2Solution = (version: Optional<string>): boolean => {
    if (!version) {
      return false;
    }

    const regex = /^(.*?)?(\d+)\.(\d+)\.(\d+)(.*)$/;
    const matches = version.match(regex);
    let majorVersion = SOFTWARE_VERSION_CONNECT_VNC_MAJOR;
    let minorVersion = SOFTWARE_VERSION_CONNECT_VNC_MINOR;

    if (matches) {
      const [, _prefix, major, minor, _patch, _suffix] = matches;
      majorVersion = Number(major);
      minorVersion = Number(minor);
    }

    return Number.isNaN(majorVersion)
      ? true
      : majorVersion >= SOFTWARE_VERSION_CONNECT_VNC_MAJOR && minorVersion >= SOFTWARE_VERSION_CONNECT_VNC_MINOR;
  };
}
