import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFeatureFlagConfig, initialFeatureFlagState } from '../../../../config/feature-flags';
import { IActionCreatorErrorOptions } from '../../state-management/interfaces/StateManagement.types';

export interface IFeatureFlagState {
  features: IFeatureFlagConfig;
  isLoading: boolean;
  isInitialized: boolean;
}

export const initialState: IFeatureFlagState = {
  features: { ...initialFeatureFlagState },
  isLoading: false,
  isInitialized: false,
};

const featureFlagSlice = createSlice({
  name: 'feature-flags',
  initialState,
  reducers: {
    getFeatureFlagConfigRequest: state => {
      state.isLoading = true;
      return state;
    },
    getFeatureFlagConfigSuccess: (state, action: PayloadAction<IFeatureFlagConfig>) => {
      state.isLoading = false;
      state.isInitialized = true;
      state.features = action.payload;
      return state;
    },
    getFeatureFlagConfigError: (state, _action: PayloadAction<IActionCreatorErrorOptions>) => {
      state.isLoading = false;
      state.isInitialized = true;
      return state;
    },
    setFeatureFlagConfig: (state, action: PayloadAction<IFeatureFlagConfig>) => {
      state.features = action.payload;
      return state;
    },
  },
});

export const FeatureFlagActions = featureFlagSlice.actions;
export const featureFlagReducer = featureFlagSlice.reducer;
