import { CloseCircleFilled, InfoCircleFilled, WarningFilled } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { intersection, isEqual, uniq, without } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '../../../lib/components/Button/SecondaryButton/SecondaryButton';
import { Checkbox } from '../../../lib/components/Checkbox/Checkbox';
import { Modal } from '../../../lib/components/Modal/Modal';
import { ColumnsTypeForCustomizableTable } from '../../../lib/components/Table/Table';
import { useDependencies } from '../../cross-cutting-concerns/dependency-injection/hooks/useDependencies';
import { StyledColumnCustomizerPanel } from './ColumnCustomizerPanel.styles';
import { ColumnCustomizerItem } from './ColumnCustomizerItem/ColumnCustomizerItem';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { PopConfirm } from 'lib/components/PopConfirm/PopConfirm';

const COLUMN_CUSTOMIZATION_CONFIG_MINIMUM_LENGTH = 2;
const COLUMN_CUSTOMIZER_PANEL_SKIP_RESET_CONFIRMATION_KEY = 'column-customizer-panel-skip-reset-confirmation';
const COLUMN_CUSTOMIZER_PANEL_SKIP_DISCARD_CONFIRMATION_KEY = 'column-customizer-panel-skip-discard-confirmation';

export type ColumnCustomizationConfig<TColumnId> = TColumnId[];

export const ColumnCustomizerPanel = <TColumnId, RecordType = unknown>({
  columnDefinitions,
  columnCustomizationConfig,
  onSubmit,
  onCancel,
}: {
  columnDefinitions: ColumnsTypeForCustomizableTable<TColumnId, RecordType>;
  columnCustomizationConfig: ColumnCustomizationConfig<TColumnId>;
  onSubmit: (
    newConfig: ColumnCustomizationConfig<TColumnId>,
    hiddenColumns: ColumnCustomizationConfig<TColumnId>
  ) => void;
  onCancel: () => void;
}): React.JSX.Element => {
  const { t } = useTranslation();
  const { browserStorage } = useDependencies();
  let skipColumnCustomizationResetConfirmation = false;
  let skipColumnCustomizationDiscardConfirmation = false;
  const columnIdsInColumnDefinitionOrder: ColumnCustomizationConfig<TColumnId> = columnDefinitions.map(
    column => column.columnId
  );
  const customizableColumnDefinitions = columnDefinitions.filter(columnDefinition => columnDefinition.customizable);
  const allCustomizableColumnIds = customizableColumnDefinitions.map(column => column.columnId);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);
  const [activeColumns, setActiveColumns] = useState<ColumnCustomizationConfig<TColumnId>>(columnCustomizationConfig);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState<boolean>(false);
  const [isDiscardConfirmationOpen, setIsDiscardConfirmationOpen] = useState<boolean>(false);
  const [isValidationMessageOpen, setIsValidationMessageOpen] = useState<boolean>(false);
  const [dontShowDiscardConfirmationChecked, setDontShowDiscardConfirmationChecked] = useState<boolean>(false);
  // Using intersection so the order from prop columnCustomizationConfig is preserved
  // @see https://lodash.com/docs/4.17.15#intersection
  const newColumnCustomizationConfig: ColumnCustomizationConfig<TColumnId> = useMemo(
    () => intersection(columnIdsInColumnDefinitionOrder, activeColumns),
    [activeColumns, columnIdsInColumnDefinitionOrder]
  );
  const countOfCustomizableColumnsInNewConfig = useMemo(
    () => newColumnCustomizationConfig.filter(columnId => allCustomizableColumnIds.includes(columnId)),
    [allCustomizableColumnIds, newColumnCustomizationConfig]
  );
  const hiddenColumnIds: ColumnCustomizationConfig<TColumnId> = useMemo(
    () => without(columnIdsInColumnDefinitionOrder, ...newColumnCustomizationConfig),
    [columnIdsInColumnDefinitionOrder, newColumnCustomizationConfig]
  );
  const hasConfigChanged = useMemo(
    () => !isEqual(columnCustomizationConfig, newColumnCustomizationConfig),
    [columnCustomizationConfig, newColumnCustomizationConfig]
  );

  try {
    const hasSeenWarning = browserStorage.get(COLUMN_CUSTOMIZER_PANEL_SKIP_RESET_CONFIRMATION_KEY);

    if (hasSeenWarning) {
      skipColumnCustomizationResetConfirmation = hasSeenWarning === 'true';
    } else {
      skipColumnCustomizationResetConfirmation = false;
    }
  } catch (error) {
    skipColumnCustomizationResetConfirmation = false;
  }

  try {
    const optedOutOfDiscardWarning = browserStorage.get(COLUMN_CUSTOMIZER_PANEL_SKIP_DISCARD_CONFIRMATION_KEY);

    if (optedOutOfDiscardWarning) {
      skipColumnCustomizationDiscardConfirmation = optedOutOfDiscardWarning === 'true';
    } else {
      skipColumnCustomizationDiscardConfirmation = false;
    }
  } catch (error) {
    skipColumnCustomizationDiscardConfirmation = false;
  }

  useEffect(() => {
    if (isEqual(newColumnCustomizationConfig, allCustomizableColumnIds)) {
      setIsSelectAllChecked(true);
    } else {
      setIsSelectAllChecked(false);
    }
  }, [allCustomizableColumnIds, newColumnCustomizationConfig]);

  const submit = (): void => {
    onSubmit(newColumnCustomizationConfig, hiddenColumnIds);
  };

  const validate = (): boolean => {
    const configIsValid = countOfCustomizableColumnsInNewConfig.length >= COLUMN_CUSTOMIZATION_CONFIG_MINIMUM_LENGTH;

    return configIsValid;
  };

  const showResetConfirmationOrSubmit = (newOpen: boolean): void => {
    if (hasConfigChanged && !skipColumnCustomizationResetConfirmation) {
      setIsResetConfirmationOpen(newOpen);
    } else {
      submit();
    }
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent): void => {
    if (e.target.checked) {
      setActiveColumns(allCustomizableColumnIds);
    }

    if (!e.target.checked) {
      setActiveColumns([]);
    }
  };

  const handleColumnCheckboxChange = (e: CheckboxChangeEvent, value: string): void => {
    if (e.target.checked) {
      const newActiveColumns: ColumnCustomizationConfig<TColumnId> = uniq([
        ...activeColumns,
        value as unknown as TColumnId,
      ]);
      setActiveColumns(newActiveColumns);
    }

    if (!e.target.checked) {
      const newActiveColumns: ColumnCustomizationConfig<TColumnId> = without(
        activeColumns,
        value as unknown as TColumnId
      );
      setActiveColumns(newActiveColumns);
    }
  };

  const handleCancelButtonConfirmationOpenChange = (newOpen: boolean): void => {
    if (hasConfigChanged && !skipColumnCustomizationDiscardConfirmation) {
      setIsDiscardConfirmationOpen(newOpen);
    } else {
      onCancel();
    }
  };

  const handleDiscardConfirmationCancel = (): void => {
    setIsDiscardConfirmationOpen(false);
  };

  const handleDiscardConfirmationConfirm = (): void => {
    if (dontShowDiscardConfirmationChecked) {
      browserStorage.set(COLUMN_CUSTOMIZER_PANEL_SKIP_DISCARD_CONFIRMATION_KEY, 'true');
    }

    onCancel();
  };

  const handleDontShowDiscardConfirmationChecked = (e: CheckboxChangeEvent): void => {
    setDontShowDiscardConfirmationChecked(e.target.checked);
  };

  const handleSaveButtonConfirmationOpenChange = (newOpen: boolean): void => {
    const configIsValid = validate();

    if (configIsValid) {
      showResetConfirmationOrSubmit(newOpen);
    } else {
      setIsValidationMessageOpen(true);
    }
  };

  const handleResetConfirmationConfirm = (): void => {
    browserStorage.set(COLUMN_CUSTOMIZER_PANEL_SKIP_RESET_CONFIRMATION_KEY, 'true');
    submit();
  };

  return (
    <StyledColumnCustomizerPanel>
      <div className="column-customizer-panel__header">
        <h1 className="column-customizer-panel__title">{t('columnCustomizerPanel.title')}</h1>

        <Checkbox
          className="column-customizer-panel__select-all-checkbox"
          checked={isSelectAllChecked}
          onChange={handleSelectAllChange}
        >
          <div className="column-customizer-panel__checkbox-label">{t('common.selectAll')}</div>
        </Checkbox>
      </div>
      <div className="column-customizer-panel__body">
        {customizableColumnDefinitions.map(def => (
          <ColumnCustomizerItem
            label={String(def.columnLabel)}
            value={String(def.columnId)}
            key={String(def.columnId)}
            checked={activeColumns.includes(def.columnId)}
            onChange={handleColumnCheckboxChange}
          />
        ))}
      </div>
      <div className="column-customizer-panel__footer">
        <PopConfirm
          title={t('common.discarding.discardingChanges')}
          description={
            <>
              <div className="popconfirm__paragraph">{t('common.discarding.description')}</div>
              <div>
                <Checkbox
                  checked={dontShowDiscardConfirmationChecked}
                  onChange={handleDontShowDiscardConfirmationChecked}
                >
                  <div className="popconfirm__checkbox-label">{t('common.doNotShowAgain')}</div>
                </Checkbox>
              </div>
            </>
          }
          icon={<WarningFilled />}
          okText={t('common.discarding.yesDiscard')}
          cancelText={t('common.noThanks')}
          open={isDiscardConfirmationOpen}
          onOpenChange={handleCancelButtonConfirmationOpenChange}
          onCancel={handleDiscardConfirmationCancel}
          onConfirm={handleDiscardConfirmationConfirm}
        >
          <SecondaryButton size="m" className="column-customizer-panel__cancel-button">
            {t('common.cancel')}
          </SecondaryButton>
        </PopConfirm>
        <PopConfirm
          title={t('columnCustomizerPanel.modals.resetConfirmation.title')}
          description={t('columnCustomizerPanel.modals.resetConfirmation.description')}
          icon={<InfoCircleFilled />}
          okText={t('common.okProceed')}
          cancelText={t('common.goBack')}
          open={isResetConfirmationOpen}
          onOpenChange={handleSaveButtonConfirmationOpenChange}
          onCancel={(): void => setIsResetConfirmationOpen(false)}
          onConfirm={handleResetConfirmationConfirm}
        >
          <PrimaryButton size="m" className="column-customizer-panel__save-button">
            {t('common.saveAdjustments')}
          </PrimaryButton>
        </PopConfirm>
      </div>
      <Modal
        className="modal--rounded"
        open={isValidationMessageOpen}
        footer={
          <>
            <PrimaryButton size="m" onClick={(): void => setIsValidationMessageOpen(false)}>
              {t('common.okGoBack')}
            </PrimaryButton>
          </>
        }
      >
        <div className="modal__content">
          <div className="modal__icon modal__icon--error">
            <CloseCircleFilled />
          </div>
          <div className="modal__message">
            <div className="modal__title--lg-strong">{t('columnCustomizerPanel.modals.validationMessage.title')}</div>
            <div className="modal__description">{t('columnCustomizerPanel.modals.validationMessage.description')}</div>
          </div>
        </div>
      </Modal>
    </StyledColumnCustomizerPanel>
  );
};
