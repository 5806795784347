import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VncModalSelectors } from '../../state/vncModalSelectors';
import { VncModalActions } from '../../state/vncModalSlice';
import { StyledVncExpirationModal } from './VncExpirationModal.styles';
import { VncActions } from 'app/components/Vnc/state/vncSlice';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import {
  LOCAL_STORAGE_IS_USING_VNC_V2_KEY,
  LOCAL_STORAGE_VNC_KEY,
  LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY,
  LOCAL_STORAGE_VNC_SERIAL_NUMBER,
} from 'config/constants';
import { VncUtils } from 'app/components/Vnc/util/VncUtils';

export const VncExpirationModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isModalVisible = !!useSelector(VncModalSelectors.selectVncModalExpirationIsVisible);
  const machineIdLocalStored = localStorage.getItem(LOCAL_STORAGE_VNC_KEY);
  const robotSoftwareVersion = localStorage.getItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);

  /**
   * @context session is expired
   * @description Handle action to close timeout message when the current session is expired
   */
  const handleHideModal = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_VNC_KEY);
    localStorage.removeItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER);
    localStorage.removeItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);
    localStorage.removeItem(LOCAL_STORAGE_IS_USING_VNC_V2_KEY);
    dispatch(VncModalActions.vncModalExpirationHide());
    dispatch(VncActions.vncCloseWindow());
  }, [dispatch]);

  const handleEffectiveRequestVncSession = useCallback(() => {
    if (VncUtils.isUsingVncV2Solution(robotSoftwareVersion)) {
      dispatch(VncActions.vncShowWindowV2());
      localStorage.setItem('LOCAL_STORAGE_IS_USING_VNC_V2_KEY', 'true');

      return dispatch(
        VncActions.vncRequestUrlV2Request({
          machineId: machineIdLocalStored || '',
          softwareVersion: robotSoftwareVersion,
        })
      );
    }

    dispatch(VncActions.vncHideWindowV2());
    return dispatch(
      VncActions.vncRequestUrlRequest({
        machineId: machineIdLocalStored || '',
        softwareVersion: robotSoftwareVersion,
      })
    );
  }, [dispatch, machineIdLocalStored, robotSoftwareVersion]);

  /**
   * @context session is expired
   * @description Handle action to access VNC session when the current session is expired
   */
  const handleRequestVncUrl = useCallback(() => {
    dispatch(VncModalActions.vncModalExpirationHide());
    handleEffectiveRequestVncSession();
  }, [dispatch, handleEffectiveRequestVncSession]);

  return (
    <StyledVncExpirationModal
      title={t('vnc.modals.expiration.title')}
      open={isModalVisible}
      className="vnc-expiration-modal"
      closable={false}
      width={550}
      destroyOnClose
      footer={[
        <SecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleHideModal}>
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton size="m" key="submit-button" className="submit-button" onClick={handleRequestVncUrl}>
          {t('vnc.request')}
        </PrimaryButton>,
      ]}
    >
      <p className="vnc-expiration-modal__description">{t('vnc.modals.expiration.description')}</p>
    </StyledVncExpirationModal>
  );
};
