import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { StyledDownloadHeader } from './DownloadHeader.styles';
import { LegacyPrimaryButton } from 'lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

export const DownloadHeader = ({ title, href }: { title: string; href: string }): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDownloadButtonVisible = href.length > 0;

  return (
    <StyledDownloadHeader>
      <Button
        type="text"
        icon={<SvgIcon name="back" />}
        onClick={(): void => navigate(-1)}
        className="download-header__back-button"
      />
      <h2>{title}</h2>
      <LegacyPrimaryButton
        size="m"
        className={classnames('download-header__download-button', {
          'download-header__download-button--hidden': !isDownloadButtonVisible,
        })}
        href={href}
        download
      >
        {t('common.download')}
      </LegacyPrimaryButton>
    </StyledDownloadHeader>
  );
};
