import { isNil } from 'lodash-es';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pages } from '../../../../config/pages';
import { useCurrentPathPattern } from '../../routing/hooks/useCurrentPathPattern';
import { AnalyticsActions } from '../state/analyticsSlice';

export interface UseAnalyticsSetPageInfoOptions {
  pageName?: string;
}

/**
 * Hook set page information for analytics
 */
export function useAnalyticsSetPageInfo({ pageName }: UseAnalyticsSetPageInfoOptions): void {
  const dispatch = useDispatch();
  const currentPathPattern = useCurrentPathPattern();
  let effectivePageName: string;
  let effectivePageId: number;

  if (pageName) {
    effectivePageName = pageName;
  } else if (isNil(currentPathPattern) || isNil(pages[currentPathPattern]?.pageName)) {
    console.error(
      `No pageName was defined for path pattern "${currentPathPattern}". Please update mapping at config/pages.ts.`
    );

    effectivePageName = '';
  } else {
    effectivePageName = pages[currentPathPattern]?.pageName ?? '';
  }

  if (isNil(currentPathPattern) || isNil(pages[currentPathPattern]?.pageId)) {
    console.error(
      `No pageId was defined for path pattern "${currentPathPattern}". Please update mapping at config/pages.ts.`
    );

    effectivePageId = 0;
  } else {
    effectivePageId = pages[currentPathPattern]?.pageId ?? 0;
  }

  const hasInvalidValue = effectivePageName.includes('undefined');

  useEffect(() => {
    if (hasInvalidValue) {
      return;
    }

    dispatch(
      AnalyticsActions.setPageInfo({
        pageName: effectivePageName,
        pathPattern: currentPathPattern,
        pageId: effectivePageId,
      })
    );
  }, [currentPathPattern, dispatch, effectivePageId, effectivePageName, hasInvalidValue, pageName]);
}
