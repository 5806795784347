import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IAnalyticsFilter } from '../interfaces/Analytics.types';
import { AnalyticsActions } from '../state/analyticsSlice';

type GetActiveFiltersCallback = () => IAnalyticsFilter[];

export interface UseAnalyticsSetFilterOptions {
  getActiveFiltersCallback: GetActiveFiltersCallback;
}

/**
 * Hook to set filters for analytics
 */
export function useAnalyticsSetFilter({ getActiveFiltersCallback }: UseAnalyticsSetFilterOptions): void {
  const dispatch = useDispatch();

  const filters = getActiveFiltersCallback();

  useEffect(() => {
    if (typeof getActiveFiltersCallback === 'function') {
      dispatch(AnalyticsActions.setFilter(filters));
    }

    return () => {
      dispatch(AnalyticsActions.setFilter([]));
    };
  }, [dispatch, filters, getActiveFiltersCallback]);
}
