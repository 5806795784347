import styled from 'styled-components';

export const StyledLayout = styled.div`
  .ant-layout {
    background-color: transparent;
  }

  .ant-layout-content {
    background-color: ${(props): string => props.theme.colors.white};
    overflow: auto;
  }

  .layout-content {
    height: calc(100vh - 94px);
  }

  .outlet-wrapper {
    min-height: calc(100vh - 343px);
  }
`;
