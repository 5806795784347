import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Button, Drawer, Spin } from 'antd';
import { VncExpirationModal } from '../modals/components/VncExpirationModal/VncExpirationModal';
import { VncStatusModal } from '../modals/components/VncStatusModal/VncStatusModal';
import { VncModalSelectors } from '../modals/state/vncModalSelectors';
import { VncSelectors } from '../state/vncSelectors';
import { VncActions } from '../state/vncSlice';
import { VncModalActions } from '../modals/state/vncModalSlice';
import { StyledVnc } from './Vnc.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import vncPlaceholder from 'lib/assets/images/icons/Remote_Control_Placeholder.png';
import { MachineVncStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  LOCAL_STORAGE_IS_USING_VNC_V2_KEY,
  LOCAL_STORAGE_VNC_KEY,
  LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY,
  LOCAL_STORAGE_VNC_SERIAL_NUMBER,
} from 'config/constants';

export const Vnc = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nowIntervalRef = useRef<any>(null);
  const nowRef = useRef(new Date().toISOString());
  const machineIdLocalStored = localStorage.getItem(LOCAL_STORAGE_VNC_KEY);
  const serialNumber = localStorage.getItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER);
  const robotSoftwareVersion = localStorage.getItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);

  const vncUrl = useSelector(VncSelectors.selectVncUrl) || '';
  const vncExpiresAt = useSelector(VncSelectors.selectVncExpiresAt) || '';
  const isRequestingVncUrlLoading = !!useSelector(VncSelectors.selectVncUrlIsLoading);
  const machineIdSelector = useSelector(VncSelectors.selectVncMachineId) || '';
  const isRequestingVncUrlDone = !!useSelector(VncSelectors.selectVncUrlIsCallingDone);
  const vncUrlStatus = useSelector(VncSelectors.selectVncStatus);
  const isGettingVncStatusLoading = !!useSelector(VncSelectors.selectVncStatusIsLoading);

  const isVncWindowOpen = !!useSelector(VncSelectors.selectVncWindowIsOpen);
  const isVncWindowMinimized = useSelector(VncSelectors.selectVncWindowIsMinimized);
  const isVncWindowExpanded = useSelector(VncSelectors.selectVncWindowIsExpanded);

  const isStatusModalVisible = useSelector(VncModalSelectors.selectVncModalStatusIsVisible);
  const isExpirationModalVisible = useSelector(VncModalSelectors.selectVncModalExpirationIsVisible);

  const hasCachedEnabled = (): boolean => {
    if (machineIdLocalStored) {
      return true;
    }

    return false;
  };

  const isVncOpened = vncUrlStatus && hasCachedEnabled() && vncUrlStatus === MachineVncStatus.Running;
  const isCheckingCacheStatus = isRequestingVncUrlLoading && hasCachedEnabled();
  const isVncLaunching = isCheckingCacheStatus || isRequestingVncUrlLoading || isGettingVncStatusLoading;
  const vncIframeClassName = classnames('vnc__body--iframe', {
    'vnc__body--iframe-expanded': isVncWindowExpanded,
    'vnc__body--iframe-small': !isVncWindowExpanded,
  });

  /**
   * @context VNC session is still valid but user force to hide the Iframe
   * @description Handle disconnect VNC session when clicking on "Exit remote control"
   *              in this case, the current session will be hidden
   *              and the status of this session will be cached in both local storage and VNC server)
   */
  const handleCloseVncWindow = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_VNC_KEY);
    localStorage.removeItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER);
    localStorage.removeItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);
    localStorage.removeItem(LOCAL_STORAGE_IS_USING_VNC_V2_KEY);
    dispatch(VncActions.vncRequestUrlResetState());
    dispatch(VncActions.vncGetUrlStatusResetState());
    dispatch(VncActions.vncGetUrlStatusCancel());
    dispatch(VncActions.vncRequestUrlCancel());
    dispatch(VncActions.vncCloseWindow());
    dispatch(VncActions.vncResetWindow());
  }, [dispatch]);

  const handleMinimizeVncWindow = useCallback(() => {
    dispatch(VncActions.vncMinimizeWindow());
  }, [dispatch]);

  const handleUnMinimizeVncWindow = useCallback(() => {
    dispatch(VncActions.vncUnMinimizeWindow());
  }, [dispatch]);

  const handleExpandVncWindow = useCallback(() => {
    dispatch(VncActions.vncExpandWindow());
  }, [dispatch]);

  const handleUnExpandVncWindow = useCallback(() => {
    dispatch(VncActions.vncUnExpandWindow());
  }, [dispatch]);

  const handleOpenCacheVncWindow = useCallback(() => {
    dispatch(VncActions.vncCacheWindow());
  }, [dispatch]);

  /**
   * @context initialing a new VNC session
   * @description Handle action to show timeout message when initialing a new VNC session
   */
  const handleHideVncStatusModal = useCallback(() => {
    dispatch(VncModalActions.vncModalStatusHide());
    handleCloseVncWindow();
  }, [dispatch, handleCloseVncWindow]);

  useEffect(() => {
    if (vncUrl && isVncWindowOpen) {
      dispatch(VncActions.vncGetUrlStatusRequest({ machineId: machineIdLocalStored || machineIdSelector }));
    }
  }, [vncUrl, dispatch, machineIdLocalStored, isVncWindowOpen, machineIdSelector]);

  /**
   * @description to be executed every second to validate the session expiration state
   */
  useEffect(() => {
    nowIntervalRef.current = setInterval(() => {
      nowRef.current = new Date().toISOString();

      if (nowRef.current >= vncExpiresAt && vncUrl) {
        if (isVncOpened) {
          dispatch(VncModalActions.vncModalExpirationShow());
        }

        dispatch(VncActions.vncRequestUrlResetState());
        dispatch(VncActions.vncGetUrlStatusResetState());
      }
    }, 1000);

    return () => clearInterval(nowIntervalRef.current);
  });

  /**
   * @description Double check if the session is cached in VNC server
   */
  useEffect(() => {
    if (hasCachedEnabled() && !vncUrl && !isVncWindowOpen) {
      dispatch(
        VncActions.vncRequestUrlRequest({
          machineId: machineIdLocalStored || '',
          softwareVersion: robotSoftwareVersion,
        })
      );
      handleOpenCacheVncWindow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Close vnc session container when user can not get session endpoint
   */
  useEffect(() => {
    if (!vncUrl && isRequestingVncUrlDone) {
      dispatch(VncActions.vncRequestUrlResetState());
      handleCloseVncWindow();
      localStorage.removeItem(LOCAL_STORAGE_VNC_KEY);
      localStorage.removeItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER);
      localStorage.removeItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY);
      localStorage.removeItem(LOCAL_STORAGE_IS_USING_VNC_V2_KEY);
    }
  }, [dispatch, vncUrl, isRequestingVncUrlDone, handleCloseVncWindow]);

  /**
   * @context Component unmount
   */
  useEffect(
    () => () => {
      dispatch(VncActions.vncRequestUrlResetState());
    },
    [dispatch]
  );

  const title = t('vnc.title') + (serialNumber ? ` / ${serialNumber}` : '');

  const vncClassName = classnames('vnc', {
    'vnc-expanded': isVncWindowExpanded,
    'vnc-small': !isVncWindowExpanded,
    'vnc-minimize': isVncWindowMinimized,
  });

  const renderDrawerExtra = (): JSX.Element => (
    <>
      <Button
        icon={<SvgIcon name="minimize" />}
        className="minimize-button"
        onClick={isVncWindowMinimized ? handleUnMinimizeVncWindow : handleMinimizeVncWindow}
        type="link"
      />
      {isVncWindowExpanded ? (
        <Button
          icon={<SvgIcon name="shrink" />}
          className="shrink-button"
          onClick={handleUnExpandVncWindow}
          type="link"
        />
      ) : (
        <Button
          icon={<SvgIcon name="expand" />}
          className="expand-button"
          onClick={handleExpandVncWindow}
          type="link"
        />
      )}
      <Button icon={<SvgIcon name="xmark" />} className="close-button" onClick={handleCloseVncWindow} type="link" />
    </>
  );

  return (
    <>
      {isVncWindowOpen && (
        <StyledVnc className={vncClassName}>
          <Drawer
            title={title}
            placement="bottom"
            closable={false}
            getContainer={false}
            mask={false}
            onClose={handleCloseVncWindow}
            destroyOnClose
            open={isVncWindowOpen}
            extra={renderDrawerExtra()}
          >
            <div className="vnc__body">
              {!isVncOpened ? (
                <Spin tip={t('vnc.waitingText')} spinning={isVncLaunching} size="large" className="spinning">
                  <img src={vncPlaceholder} alt="vnc-placeholder" />
                </Spin>
              ) : (
                <iframe
                  className={vncIframeClassName}
                  src={vncUrl}
                  title="VNC session for controlling machine remotely"
                />
              )}
            </div>
          </Drawer>
        </StyledVnc>
      )}

      {isExpirationModalVisible && <VncExpirationModal />}
      {isStatusModalVisible && <VncStatusModal handleHideModal={handleHideVncStatusModal} />}
    </>
  );
};
