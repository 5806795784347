import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VncModalSelectors } from '../../state/vncModalSelectors';
import { VncModalActions } from '../../state/vncModalSlice';
import { StyledVncWarningOneIsOpenAlreadyModal } from './VncWarningOneIsOpenAlreadyModal.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { VncActions } from 'app/components/Vnc/state/vncSlice';
import { VncUtils } from 'app/components/Vnc/util/VncUtils';
import { selectData } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelSelectors';
import {
  LOCAL_STORAGE_VNC_KEY,
  LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY,
  LOCAL_STORAGE_VNC_SERIAL_NUMBER,
} from 'config/constants';

interface VncWarningOneIsOpenAlreadyModalProps {
  robotIdParams: string;
}

export const VncWarningOneIsOpenAlreadyModal = ({
  robotIdParams,
}: VncWarningOneIsOpenAlreadyModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Note: this modal appears when the vnc button is triggered in a machine details panel
  // and there is already once vnc is running
  // So we can know the robot data here belongs to what machine
  const robotDetailsData = useSelector(selectData);
  const isModalVisible = !!useSelector(VncModalSelectors.selectVncModalWarningOneIsOpenAlreadyIsVisible);

  const handleVncWindowReset = useCallback(() => {
    dispatch(VncActions.vncResetWindow());
    dispatch(VncActions.vncOpenWindow());
  }, [dispatch]);

  const handleVncCancelUrlAndStatus = useCallback(() => {
    dispatch(VncActions.vncRequestUrlCancel());
    dispatch(VncActions.vncGetUrlStatusCancel());
    dispatch(VncActions.vncRequestUrlV2Cancel());
    dispatch(VncActions.vncGetUrlStatusV2Cancel());
  }, [dispatch]);

  const setVncInformationLocalStorage = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_VNC_KEY, robotIdParams);
    localStorage.setItem(LOCAL_STORAGE_VNC_SERIAL_NUMBER, robotDetailsData?.serialNumber || '');
    localStorage.setItem(LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY, robotDetailsData?.softwareVersion || '');
  }, [robotDetailsData?.softwareVersion, robotDetailsData?.serialNumber, robotIdParams]);

  const handleEffectiveRequestVncSession = useCallback(() => {
    if (VncUtils.isUsingVncV2Solution(robotDetailsData?.softwareVersion)) {
      dispatch(VncActions.vncShowWindowV2());
      localStorage.setItem('LOCAL_STORAGE_IS_USING_VNC_V2_KEY', 'true');

      return dispatch(
        VncActions.vncRequestUrlV2Request({
          machineId: robotIdParams,
          softwareVersion: robotDetailsData?.softwareVersion,
        })
      );
    }

    dispatch(VncActions.vncHideWindowV2());
    return dispatch(
      VncActions.vncRequestUrlRequest({ machineId: robotIdParams, softwareVersion: robotDetailsData?.softwareVersion })
    );
  }, [dispatch, robotIdParams, robotDetailsData]);

  /**
   * @context pop up message to let user know that there is a VNC session running
   * @description Handle action to close warning message about already one vnc is running
   */
  const handleHideModal = useCallback(() => {
    dispatch(VncModalActions.vncModalWarningOneIsOpenAlreadyHide());
  }, [dispatch]);

  /**
   * @context pop up message to let user know that there is a VNC session running
   * @description Handle action to close current vnc session and open new vnc session with new machine
   */
  const handleRequestVncUrl = useCallback(() => {
    dispatch(VncModalActions.vncModalWarningOneIsOpenAlreadyHide());
    handleVncCancelUrlAndStatus();
    handleVncWindowReset();
    setVncInformationLocalStorage();
    handleEffectiveRequestVncSession();
  }, [
    dispatch,
    handleEffectiveRequestVncSession,
    setVncInformationLocalStorage,
    handleVncCancelUrlAndStatus,
    handleVncWindowReset,
  ]);

  return (
    <StyledVncWarningOneIsOpenAlreadyModal
      title={t('vnc.modals.oneIsOpenAlready.title')}
      open={isModalVisible}
      className="vnc-warning-one-is-open-already-modal"
      closable={false}
      width={550}
      destroyOnClose
      footer={[
        <SecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleHideModal}>
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton size="m" key="submit-button" className="submit-button" onClick={handleRequestVncUrl}>
          {t('vnc.request')}
        </PrimaryButton>,
      ]}
    >
      <p className="vnc-warning-one-is-open-already-modal__description">
        {t('vnc.modals.oneIsOpenAlready.description')}
      </p>
    </StyledVncWarningOneIsOpenAlreadyModal>
  );
};
