import styled from 'styled-components';

export const StyledLogin = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  .login__image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    background-image: url('/assets/images/login/campaign_targetgroups_representatives_oth_1_CI15_2017_4096x2143.jpg');
    background-size: cover;
    background-position: center center;
    filter: brightness(0.4);
  }

  .login__tint {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: ${(props): string => props.theme.colors.grey400};
    opacity: 0.5;
  }

  .login__text {
    flex: 0 0;
    z-index: 200;

    color: ${(props): string => props.theme.colors.white};
    padding: 116px 72px 0;
  }

  .login__buttons {
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    padding: 72px;
  }

  .login__heading {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    text-transform: uppercase;
    margin-bottom: ${(props): string => props.theme.spacing.md};
  }

  .login__subheading {
    ${(props): string => props.theme.fontStyles.redesign.xl.normal};
  }

  .ant-btn.button-base.login__login-button {
    width: 234px;
    text-transform: uppercase;
  }
`;
