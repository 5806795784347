import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { inject, singleton } from 'tsyringe';
import { AuthenticationService } from '../../authentication/AuthenticationService';
import { GraphQlErrorHandler } from '../middleware/GraphQlErrorHandler';

@singleton()
export class GraphQlClient extends ApolloClient<NormalizedCacheObject> {
  constructor(
    authenticationService: AuthenticationService,
    graphQlErrorHandler: GraphQlErrorHandler,
    @inject('AmApiGraphqlHttpEndpoint') apiUrlHttp: string,
    @inject('AwsRegion') awsRegion: string
  ) {
    const auth: AuthOptions = {
      type: AUTH_TYPE.OPENID_CONNECT,
      jwtToken: async () => {
        const accessToken = (await authenticationService.getTokens())?.jwt.accessToken ?? 'Unauthorized';

        return accessToken;
      },
    };

    super({
      link: ApolloLink.from([
        createAuthLink({
          url: apiUrlHttp,
          region: awsRegion,
          auth,
        }),
        onError(graphQlErrorHandler.handle),
        createSubscriptionHandshakeLink(
          {
            url: apiUrlHttp,
            region: awsRegion,
            auth,
          },
          createHttpLink({ uri: apiUrlHttp })
        ),
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        mutate: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    });
  }
}
