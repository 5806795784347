import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../../../config/route-paths';

export const NavigateToRoot = (): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(RoutePaths.ROOT);
  }, [navigate]);

  return <></>;
};
