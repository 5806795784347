import { ReactNode } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../../config/route-paths';
import { DateTime } from '../../../../lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from '../../../../lib/utils/date-handling/DateTime.types';
import { selectIsLoggedIn } from '../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { selectHasAgreedToCurrentTerms } from '../../../modules/terms-conditions/state/termsConditionsSelectors';
import { LegalInfoUtils } from '../../../utils/legal-info/LegalInfoUtils';
import { StyledAppFooter } from './AppFooter.styles';
import { CURRENT_LICENSE_AGREEMENT_VERSION } from 'app/modules/terms-conditions/constants';
import { LicenseAgreementVersions } from 'app/modules/terms-conditions/interfaces/TermsAndConditions.types';

interface AppFooterProps {
  children?: ReactNode;
  className?: string;
}

export const AppFooter = ({ children, className }: AppFooterProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const hasAgreedToCurrentTerms = useSelector(selectHasAgreedToCurrentTerms);
  const appFooterClassName = classnames(className, 'footer');
  const isLicenseAgreementEnabled = CURRENT_LICENSE_AGREEMENT_VERSION !== LicenseAgreementVersions.V0;
  const shouldShowTermsAndConditionsLinks = isLoggedIn && hasAgreedToCurrentTerms;

  return (
    <StyledAppFooter className={appFooterClassName}>
      {children}
      <div className="footer__banner">
        <div className="footer__links">
          <div className="footer__legal-links">
            {shouldShowTermsAndConditionsLinks && (
              <>
                {isLicenseAgreementEnabled && (
                  <div>
                    <Link to={RoutePaths.LICENSE_AGREEMENT} className="footer__license-agreement">
                      {t('common.licenseAgreement')}
                    </Link>
                  </div>
                )}
                <div>
                  <Link to={RoutePaths.CONTRACT_OBLIGATION}>{t('common.contractObligation')}</Link>
                </div>
              </>
            )}
            <div>
              <Link to={RoutePaths.PRIVACY_POLICY} target="_blank" rel="noreferrer noopener">
                {t('footer.links.legal.privacyPolicy')}
              </Link>
            </div>
            <div>
              <a href={LegalInfoUtils.getImprintUrl(i18n.language)} target="_blank" rel="noreferrer noopener">
                {t('footer.links.legal.imprint')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <div className="footer__copyright-year">{`© ${DateTime.formatDateByLocale(
          i18n.language,
          new Date().toISOString(),
          DATE_FORMAT_PATTERN.YEAR
        )} ${t('footer.copyright')}`}</div>
      </div>
    </StyledAppFooter>
  );
};
