import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnalyticsActions } from '../state/analyticsSlice';

export interface UseAnalyticsSetFormOptions {
  name: string;
  fields: Record<string, any>;
  isVisible: boolean;
}

/**
 * Hook to set form information for analytics
 */
export function useAnalyticsSetForm({ name, fields, isVisible }: UseAnalyticsSetFormOptions): void {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisible) {
      dispatch(
        AnalyticsActions.setForm({
          name,
          fields,
        })
      );
    } else {
      dispatch(AnalyticsActions.clearForm());
    }

    return (): void => {
      dispatch(AnalyticsActions.clearForm());
    };
  }, [dispatch, fields, isVisible, name]);
}
