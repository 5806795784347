import { notification } from 'antd';
import { ArgsProps as AntdNotificationConfig, NotificationPlacement } from 'antd/es/notification/interface';
import React from 'react';
import { singleton } from 'tsyringe';
import { ToastIcon } from './utils/ToastsIcon';

export interface ToastConfig extends AntdNotificationConfig {
  iconName?: string;
}

export interface GetDefaultConfigProp {
  className: string;
  top: number;
  placement: NotificationPlacement | undefined;
  icon: React.ReactNode;
}

@singleton()
export class ToastService {
  public success = (config: ToastConfig): void => {
    notification.success({
      ...this.getDefaultConfig(config.iconName),
      ...config,
    });
  };

  public info = (config: ToastConfig): void => {
    notification.info({
      ...this.getDefaultConfig(config.iconName),
      ...config,
    });
  };

  public warning = (config: ToastConfig): void => {
    notification.warning({
      ...this.getDefaultConfig(config.iconName),
      ...config,
    });
  };

  public error = (config: ToastConfig): void => {
    notification.error({
      ...this.getDefaultConfig(config.iconName),
      ...config,
    });
  };

  public close = (toastKey: string): void => {
    notification.destroy(toastKey);
  };

  private getDefaultConfig = (iconName?: string): GetDefaultConfigProp => ({
    className: 'custom-antd-notification-error-message',
    top: 100,
    placement: 'topRight' as NotificationPlacement | undefined,
    icon: ToastIcon(iconName) as React.ReactNode,
  });
}
