import { initReactI18next, getI18n } from 'react-i18next';
import i18next, { ResourceLanguage } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import cs from 'antd/lib/locale/cs_CZ';
import da from 'antd/lib/locale/da_DK';
import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_US';
import fr from 'antd/lib/locale/fr_FR';
import it from 'antd/lib/locale/it_IT';
import nl from 'antd/lib/locale/nl_NL';
import nb from 'antd/lib/locale/nb_NO';
import pl from 'antd/lib/locale/pl_PL';
import sv from 'antd/lib/locale/sv_SE';
import es from 'antd/lib/locale/es_ES';
import ja from 'antd/lib/locale/ja_JP';
import pt from 'antd/lib/locale/pt_PT';
import ko from 'antd/lib/locale/ko_KR';
import { Locale } from 'antd/lib/locale';
import translationsCs from '../../../config/translations/cs.json';
import translationsDa from '../../../config/translations/da.json';
import translationsDe from '../../../config/translations/de.json';
import translationsEnUs from '../../../config/translations/en-US.json';
import translationsFr from '../../../config/translations/fr.json';
import translationsIt from '../../../config/translations/it.json';
import translationsNl from '../../../config/translations/nl.json';
import translationsNb from '../../../config/translations/nb.json';
import translationsPl from '../../../config/translations/pl.json';
import translationsSv from '../../../config/translations/sv.json';
import translationsEs from '../../../config/translations/es.json';
import translationsJa from '../../../config/translations/ja.json';
import translationsPt from '../../../config/translations/pt.json';
import translationsKo from '../../../config/translations/ko.json';
import { SupportedLanguage } from '../communication/interfaces/am-api-graphql';
import { LANGUAGES_USER_SNAP_SUPPORTED } from './constants';
import { Country } from './interfaces/Translations.types';
import { isEnvVarEnabled } from 'lib/utils/env-variables/isEnvVarEnabled';
import 'dayjs/locale/cs';
import 'dayjs/locale/da';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/sv';
import 'dayjs/locale/es';
import 'dayjs/locale/ja';
import 'dayjs/locale/pt';
import 'dayjs/locale/ko';

export const supportedLanguages: {
  [language: string]: ResourceLanguage & { name: string };
} = {
  'en-US': {
    translation: translationsEnUs,
    name: 'languages.en',
  },
  cs: {
    translation: translationsCs,
    name: 'languages.cs',
  },
  da: {
    translation: translationsDa,
    name: 'languages.da',
  },
  de: {
    translation: translationsDe,
    name: 'languages.de',
  },
  fr: {
    translation: translationsFr,
    name: 'languages.fr',
  },
  it: {
    translation: translationsIt,
    name: 'languages.it',
  },
  nl: {
    translation: translationsNl,
    name: 'languages.nl',
  },
  nb: {
    translation: translationsNb,
    name: 'languages.nb',
  },
  pl: {
    translation: translationsPl,
    name: 'languages.pl',
  },
  sv: {
    translation: translationsSv,
    name: 'languages.sv',
  },
  es: {
    translation: translationsEs,
    name: 'languages.es',
  },
  ja: {
    translation: translationsJa,
    name: 'languages.ja',
  },
  pt: {
    translation: translationsPt,
    name: 'languages.pt',
  },
  ko: {
    translation: translationsKo,
    name: 'languages.ko',
  },
};

export class Translations {
  public static initialize = async (): Promise<void> => {
    await i18next
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: 'en-US',
        resources: supportedLanguages,
        detection: {
          order: ['navigator'],
        },
        debug: isEnvVarEnabled(process.env.REACT_APP_ENABLE_I18NEXT_DEBUGGING),
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
  };

  public static initializeForTests = async (): Promise<void> => {
    await i18next
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        // This causes the translation keys to be rendered instead of the translated values.
        // Useful so the tests are independent of the actual translation
        lng: 'cimode',
        resources: {
          'en-US': {
            translation: translationsEnUs,
          },
        },
        debug: isEnvVarEnabled(process.env.REACT_APP_ENABLE_I18NEXT_DEBUGGING),
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
  };

  /**
   * Extracts primary language subtag from IETF language tag
   *
   * @param locale - locale as IETF BCP 47 language tag
   * @see https://en.wikipedia.org/wiki/IETF_language_tag
   */
  public static getPrimaryLanguageSubtag(locale: string): string {
    return locale.split('-').shift() ?? 'en';
  }

  /**
   * - First extracts primary language subtag from IETF language tag.
   * - If the primary language subtag is one of the
   * supported languages it is returned. If the language is not supported the primary language subtag for
   * english ('en') is returned instead.
   *
   * @param locale - locale as IETF BCP 47 language tag
   * @see https://en.wikipedia.org/wiki/IETF_language_tag
   */
  public static getSupportedLanguageCode(locale: string): SupportedLanguage {
    const primaryLanguageSubtag = Translations.getPrimaryLanguageSubtag(locale).toUpperCase() as SupportedLanguage;
    const supportedLanguageCodeOrFallback = Object.values(SupportedLanguage).includes(primaryLanguageSubtag)
      ? primaryLanguageSubtag
      : SupportedLanguage.En;

    return supportedLanguageCodeOrFallback;
  }

  public static getAntdLocale = (): Locale => {
    const { language } = getI18n();
    const primaryLanguageSubtag = Translations.getPrimaryLanguageSubtag(language);

    switch (primaryLanguageSubtag) {
      case 'cs': {
        return cs;
      }

      case 'da': {
        return da;
      }

      case 'de': {
        return de;
      }

      case 'en': {
        return en;
      }

      case 'fr': {
        return fr;
      }

      case 'it': {
        return it;
      }

      case 'nl': {
        return nl;
      }

      case 'nb': {
        return nb;
      }

      case 'pl': {
        return pl;
      }

      case 'sv': {
        return sv;
      }

      case 'es': {
        return es;
      }

      case 'ja': {
        return ja;
      }

      case 'pt': {
        return pt;
      }

      case 'ko': {
        return ko;
      }

      default: {
        return en;
      }
    }
  };

  public static getUserSnapLocaleSupported = (): string => {
    const { language } = getI18n();
    const primaryLanguageSubTag = Translations.getPrimaryLanguageSubtag(language);

    const localeSupported = LANGUAGES_USER_SNAP_SUPPORTED[primaryLanguageSubTag];
    if (!localeSupported) {
      console.error(`Locale ${primaryLanguageSubTag} is not supported by UserSnap`);
      return LANGUAGES_USER_SNAP_SUPPORTED.en;
    }
    return localeSupported;
  };

  public static getCountryByLocale = (locale: string): Country => {
    const lowercaseLocale = locale.toLowerCase();

    if (['en-au'].includes(lowercaseLocale)) {
      return Country.AUSTRALIA;
    }

    if (['de-at', 'en-at'].includes(lowercaseLocale)) {
      return Country.AUSTRIA;
    }

    if (['de-be', 'en-be', 'fr-be', 'nl-be'].includes(lowercaseLocale)) {
      return Country.BELGIUM;
    }

    if (['da', 'da-dk', 'da-gl'].includes(lowercaseLocale)) {
      return Country.DENMARK;
    }

    if (['no-no', 'nb-no', 'nn-no', 'se-no', 'nn', 'nb'].includes(lowercaseLocale)) {
      return Country.NORWAY;
    }

    if (['sv', 'sv-se', 'se-se', 'en-se'].includes(lowercaseLocale)) {
      return Country.SWEDEN;
    }

    if (['en-gb', 'cy-gb', 'kw-gb', 'gd-gb'].includes(lowercaseLocale)) {
      return Country.UNITED_KINGDOM;
    }

    if (['de-ch', 'fr-ch', 'it-ch', 'en-ch', 'wae-ch', 'rm-ch', 'pt-ch', 'gsw-ch'].includes(lowercaseLocale)) {
      return Country.SWITZERLAND;
    }

    if (['fr', 'fr-fr', 'ca-fr', 'br-fr'].includes(lowercaseLocale)) {
      return Country.FRANCE;
    }

    if (['nl', 'nl-nl', 'en-nl', 'nds-nl', 'fy-nl'].includes(lowercaseLocale)) {
      return Country.NETHERLANDS;
    }

    if (['cs', 'cs-cz'].includes(lowercaseLocale)) {
      return Country.CZECH_REPUBLIC;
    }

    if (['de-de', 'de', 'en-de', 'ksh-de', 'nds-de', 'dsb-de', 'hsb-de'].includes(lowercaseLocale)) {
      return Country.GERMANY;
    }

    if (['it', 'it-it', 'de-it', 'ca-it', 'fur-it'].includes(lowercaseLocale)) {
      return Country.ITALY;
    }

    if (['en-us', 'lkt-us', 'es-us', 'chr-us', 'haw-us'].includes(lowercaseLocale)) {
      return Country.USA;
    }

    if (['es-es'].includes(lowercaseLocale)) {
      return Country.SPAIN;
    }

    if (['ja-jp'].includes(lowercaseLocale)) {
      return Country.JAPAN;
    }

    if (['pt', 'pt-br'].includes(lowercaseLocale)) {
      return Country.PORTUGAL;
    }

    if (['ko', 'ko-kr', 'ko-kp'].includes(lowercaseLocale)) {
      return Country.KOREA;
    }

    return Country.USA;
  };

  /**
   * Get country by ISO 3166-1 alpha-2 country code
   * @param countryCode - Country code to get country for
   *
   * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */

  public static getCountryByCountryCode = (countryCode: string): Country => {
    const lowercaseCountryCode = countryCode.toLowerCase();

    if (['au'].includes(lowercaseCountryCode)) {
      return Country.AUSTRALIA;
    }

    if (['at'].includes(lowercaseCountryCode)) {
      return Country.AUSTRIA;
    }

    if (['be'].includes(lowercaseCountryCode)) {
      return Country.BELGIUM;
    }

    if (['dk'].includes(lowercaseCountryCode)) {
      return Country.DENMARK;
    }

    if (['no'].includes(lowercaseCountryCode)) {
      return Country.NORWAY;
    }

    if (['se'].includes(lowercaseCountryCode)) {
      return Country.SWEDEN;
    }

    /**
     * `UK` is not a valid country code, but it is specified as country code
     * for the United Kingdom in SAP, so we want to support it
     *
     * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Exceptional_reservations
     */
    if (['gb', 'uk'].includes(lowercaseCountryCode)) {
      return Country.UNITED_KINGDOM;
    }

    if (['ch'].includes(lowercaseCountryCode)) {
      return Country.SWITZERLAND;
    }

    if (['fr'].includes(lowercaseCountryCode)) {
      return Country.FRANCE;
    }

    if (['nl'].includes(lowercaseCountryCode)) {
      return Country.NETHERLANDS;
    }

    if (['cz'].includes(lowercaseCountryCode)) {
      return Country.CZECH_REPUBLIC;
    }

    if (['de'].includes(lowercaseCountryCode)) {
      return Country.GERMANY;
    }

    if (['it'].includes(lowercaseCountryCode)) {
      return Country.ITALY;
    }

    if (['us'].includes(lowercaseCountryCode)) {
      return Country.USA;
    }

    if (['ja'].includes(lowercaseCountryCode)) {
      return Country.JAPAN;
    }

    if (['pt'].includes(lowercaseCountryCode)) {
      return Country.PORTUGAL;
    }

    if (['ko'].includes(lowercaseCountryCode)) {
      return Country.KOREA;
    }

    return Country.USA;
  };
}
