import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/kaercher-logo.svg';
import { useAnalyticsLinkActivated } from '../../../analytics/hooks/useAnalyticsLinkActivated';
import { StyledLoginHeader } from './LoginHeader.styles';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';

export const LoginHeader = (): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();

  return (
    <StyledLoginHeader className="login-header">
      <div className="login-header__content">
        <Link
          to={'/'}
          onClick={(): void => {
            analyticsLinkActivated({
              linkName: AnalyticsLink.HEADER_LOGO,
            });
          }}
        >
          <img src={logo} alt="logo" />
        </Link>
      </div>
    </StyledLoginHeader>
  );
};
