import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export type RestClient = AxiosInstance;
export class RestClientFactory {
  public static create(config?: AxiosRequestConfig<any>): AxiosInstance {
    // Interceptors (request/response middlewares) that apply to every request
    // should be added here
    // @see https://axios-http.com/docs/interceptors
    return axios.create({
      ...RestClientFactory.baseConfig,
      ...config,
    });
  }

  private static readonly baseConfig: AxiosRequestConfig<any> = {
    timeout: 30000,
  };
}
