import { createSlice } from '@reduxjs/toolkit';
import * as VncModalStateTypes from './vncModalState.types';

const initialVncModalStatusState: VncModalStateTypes.IVncModalStatus = {
  isVisible: null,
};

const initialVncModalExpirationState: VncModalStateTypes.IVncModalExpiration = {
  isVisible: null,
};

export const initialVncModalWarningOneIsOpenAlreadyState: VncModalStateTypes.IVncModalWarningOneIsOpenAlready = {
  isVisible: null,
};

export const initialState: VncModalStateTypes.IVncModalState = {
  vncModalStatus: initialVncModalStatusState,
  vncModalExpiration: initialVncModalExpirationState,
  vncModalWarningOneIsOpenAlready: initialVncModalWarningOneIsOpenAlreadyState,
};

const vncModalSlice = createSlice({
  name: 'vnc-modal',
  initialState,
  reducers: {
    // Vnc Modal Status
    vncModalStatusShow: state => {
      state.vncModalStatus.isVisible = true;
    },
    vncModalStatusHide: state => {
      state.vncModalStatus.isVisible = false;
    },

    // Vnc Modal Expiration
    vncModalExpirationShow: state => {
      state.vncModalExpiration.isVisible = true;
    },
    vncModalExpirationHide: state => {
      state.vncModalExpiration.isVisible = false;
    },

    // Vnc Modal Warning One Is Open Already
    vncModalWarningOneIsOpenAlreadyShow: state => {
      state.vncModalWarningOneIsOpenAlready.isVisible = true;
    },
    vncModalWarningOneIsOpenAlreadyHide: state => {
      state.vncModalWarningOneIsOpenAlready.isVisible = false;
    },
  },
});

export const VncModalActions = vncModalSlice.actions;
export const vncModalReducer = vncModalSlice.reducer;
