import styled from 'styled-components';

export const StyledRobotRoutine = styled.div`
  width: 100%;
  margin-bottom: 8px;

  .robot-routine__title {
    ${(props): string => props.theme.fontStyles.redesign.sm.normal}
    text-transform: capitalize;
    margin: 0;
  }
`;
