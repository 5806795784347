import { IFeatureFlagConfig } from '../../../../config/feature-flags';
import { IState } from '../../state-management/interfaces/IState';

export class FeatureFlagSelectors {
  public static selectFeatureFlagConfig = (state: IState): IFeatureFlagConfig =>
    state['cross-cutting-concerns']?.['feature-flags']?.features;

  public static selectAreFeatureFlagsInitialized = (state: IState): boolean =>
    state['cross-cutting-concerns']?.['feature-flags']?.isInitialized;
}
