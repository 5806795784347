// eslint-disable-next-line import/no-extraneous-dependencies
import { HubCapsule } from '@aws-amplify/core';
import { Hub } from 'aws-amplify';
import { inject, singleton } from 'tsyringe';
import { RoutePaths } from '../../../config/route-paths';
import { AuthenticationService } from './AuthenticationService';
import { AuthenticationActions } from './state/authenticationActions';

/**
 * AmplifyHubAuthEvents
 *
 * @see https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
 */
export enum AmplifyHubAuthEvents {
  COGNITO_HOSTED_UI = 'cognitoHostedUI',
  OAUTH_SIGN_OUT = 'oAuthSignOut',
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  SIGN_OUT = 'signOut',
  SIGN_IN_FAILURE = 'signIn_failure',
  TOKEN_REFRESH = 'tokenRefresh',
  TOKEN_REFRESH_FAILURE = 'tokenRefresh_failure',
  CONFIGURED = 'configured',
}

/**
 *  Authentication
 *
 *  - Sets up a refresh interval. In this interval `Auth.currentSession()` is called causing refresh and id tokens
 *  to be refreshed should they be close to expiring
 *  - Can also be used to listen to events emitted from `Amplify.Auth`s Hub and do something in response to that event
 *
 *  @see https://docs.amplify.aws/lib/utilities/hub/q/platform/js/
 */
@singleton()
export class Authentication {
  private tokenRefreshInterval: number = parseInt(process.env.REACT_APP_TOKEN_REFRESH_INTERVAL_MS || '30000', 10);

  constructor(
    private authenticationService: AuthenticationService,
    // Typescript is reporting the error "Attempted import error: 'Store' is not exported from 'redux'"
    // Set to any type as a workaround
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @inject('Store') private store: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @inject('History') private history: any
  ) {
    setInterval(this.triggerTokenRefresh, this.tokenRefreshInterval);

    Hub.listen('auth', (data: HubCapsule) => {
      switch (data.payload.event) {
        case AmplifyHubAuthEvents.TOKEN_REFRESH: {
          this.onTokenRefresh();
          break;
        }
        case AmplifyHubAuthEvents.TOKEN_REFRESH_FAILURE: {
          this.onTokenRefreshFailure(data);
          break;
        }
        case AmplifyHubAuthEvents.SIGN_IN: {
          this.onSignIn();
          break;
        }
        case AmplifyHubAuthEvents.SIGN_IN_FAILURE: {
          this.onSignInFailure(data);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  private onTokenRefresh = async (): Promise<void> => {
    const tokens = await this.authenticationService.getTokens();
    const permissions = await this.authenticationService.getPermissions();

    this.store.dispatch(
      AuthenticationActions.tokenRefreshed({
        tokens,
        permissions,
      })
    );
  };

  private onTokenRefreshFailure = async (data: HubCapsule): Promise<void> => {
    this.store.dispatch(
      AuthenticationActions.tokenRefreshFailed({
        data,
      })
    );
    await this.authenticationService.signOut();
    this.history.push(RoutePaths.LOGIN);
  };

  private triggerTokenRefresh = async (): Promise<void> => {
    try {
      await this.authenticationService.triggerTokenRefresh();

      await this.authenticationService.checkIsAccessTokenRevoked();
    } catch (error) {
      console.error(error);
    }
  };

  private onSignIn = async (): Promise<void> => {
    this.store.dispatch(
      AuthenticationActions.setIsAuthorizedFromSupportPortal({
        data: true,
      })
    );
  };

  private onSignInFailure = async (data: HubCapsule): Promise<void> => {
    const error: Error | undefined = data.payload.data;
    const isNotFoundUser = error?.message.startsWith('PreTokenGeneration failed with error User not found');
    const isNotActiveUser = /^PreTokenGeneration failed with error User .+ is not active/.test(error?.message || '');

    if (isNotFoundUser || isNotActiveUser) {
      this.store.dispatch(
        AuthenticationActions.setIsAuthorizedFromSupportPortal({
          data: false,
        })
      );
    }
  };
}
