import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class VncModalSelectors {
  public static selectVncModalStatusIsVisible = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].modals.vncModalStatus.isVisible;

  public static selectVncModalExpirationIsVisible = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].modals.vncModalExpiration.isVisible;

  public static selectVncModalWarningOneIsOpenAlreadyIsVisible = (state: IState): Optional<boolean> =>
    state['cross-cutting-concerns']['vnc-feature'].modals.vncModalWarningOneIsOpenAlready.isVisible;
}
