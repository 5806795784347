import { BrowserHistory } from 'history';
import React, { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';

export interface BrowserRouterProps {
  basename?: string;
  children?: React.ReactNode;
  history: BrowserHistory;
}

/**
 * A <Router> for use in web browsers. Provides the cleanest URLs.
 */
export function BrowserRouter({ basename, children, history }: BrowserRouterProps): JSX.Element {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router basename={basename} location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  );
}
