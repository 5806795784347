import styled from 'styled-components';

export const StyledMap = styled.div`
  .map__popup-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .map__popup-container--is-loading {
    filter: brightness(50%);
  }

  .map__loading-overlay {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    justify-content: center;
    align-items: center;
  }

  .map__top-right-control {
    right: 10px !important;
  }

  .map__fullscreen-button {
    background-color: ${(props): string => props.theme.colors.white};
    border: 2px solid ${(props): string => props.theme.colors.white};
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .map__fullscreen-button-img {
    width: 18px;
    height: 18px;
  }

  .map__enabled-fullscreen-button-img {
    display: none;
    width: 18px;
    height: 18px;
  }

  &.map--fullscreen-mode {
    .map__enabled-fullscreen-button-img {
      display: block;
    }

    .map__fullscreen-button-img {
      display: none;
    }
  }

  .gmnoprint.gm-bundled-control {
    top: 60px !important;
  }
`;
