import styled from 'styled-components';
import { Layout } from 'antd';

const { Header } = Layout;

export const StyledLoginHeader = styled(Header)`
  flex: 0 0;
  background-color: #fff;
  height: auto;
  padding: 0;

  .login-header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 94px;
    padding: 0px 72px 0px 20px;
    border-bottom: 12px solid;
    border-color: ${(props): string => props.theme.colors.darkGrey};
  }
`;
