import { Spin } from 'antd';
import classnames from 'classnames';
import GoogleMapReact, {
  Props as GoogleMapReactProps,
  ChildComponentProps as GoogleMapReactChildComponentProps,
  Maps,
} from 'google-map-react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { StyledMap } from './Map.styles';
import { addFullscreenControl } from './addFullscreenControl';
import { useAppConfig } from 'app/cross-cutting-concerns/configuration/hooks/useAppConfig';
import { CENTER_FALLBACK, ZOOM_FALLBACK } from 'lib/utils/map/MapUtils';

type MapProps = GoogleMapReactProps & {
  className?: string;
  isLoading?: boolean;
  children:
    | React.ReactElement<GoogleMapReactChildComponentProps>
    | React.ReactElement<GoogleMapReactChildComponentProps>[];
  onGoogleApisInitialized?: ({ mapInstance, mapApis }: { mapInstance: any; mapApis: any }) => void;
};

type MapPropsWithForwardedRef = MapProps & {
  forwardedRef: React.ForwardedRef<HTMLDivElement>;
};

export const GEOFENCE_DEFAULT_RADIUS = 300;
export const GEOFENCE_MINIMUM_RADIUS = 300;

const MapComponent: React.FC<MapPropsWithForwardedRef> = ({
  className = '',
  isLoading = false,
  children,
  center,
  zoom,
  forwardedRef,
  onDrag,
  onZoomAnimationStart,
  onGoogleApisInitialized,
  ...rest
}): JSX.Element => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const isFullscreenRef = useRef<boolean>(false);
  const mapInstanceRef = useRef<any>(null);
  const mapApisRef = useRef<any>(null);
  const mapElementRef = useRef<HTMLDivElement>(null);
  const mapClassName = classnames('map', className, {
    'map--fullscreen-mode': isFullscreen,
  });
  const popupContainerClassName = classnames('map__popup-container', {
    'map__popup-container--is-loading': isLoading,
  });
  const { i18n } = useTranslation();
  const {
    googleMaps: { apiKey },
  } = useAppConfig();

  useEffect((): (() => void) | undefined => {
    const mapElement = mapElementRef.current;
    if (!mapElement) return undefined;

    const onFullscreenChanged = (): void => {
      // Use reference variable for onClick callback as it does not rerender
      isFullscreenRef.current = !!document.fullscreenElement;
      // Use state variable for force rerender class names of the Map component to show/hide fullscreen button images
      setIsFullscreen(!!document.fullscreenElement);
    };

    mapElement.addEventListener('fullscreenchange', onFullscreenChanged);

    return (): void => {
      mapElement.removeEventListener('fullscreenchange', onFullscreenChanged);
    };
  }, [mapElementRef]);

  const handleClickFullscreenButton = (): void => {
    if (mapElementRef.current) {
      if (!isFullscreenRef.current) {
        mapElementRef.current.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  };

  const addTopRightControlToMap = ({ map, maps }: { map: any; maps: Maps }): void => {
    if (document.fullscreenEnabled) {
      const topRightControlDiv = document.createElement('div');
      topRightControlDiv.className = 'map__top-right-control';

      addFullscreenControl({
        onClick: handleClickFullscreenButton,
        controlDiv: topRightControlDiv,
      });
      map.controls[maps.ControlPosition.TOP_RIGHT].push(topRightControlDiv);
    }
  };

  return (
    <StyledMap className={mapClassName} ref={mapElementRef}>
      <div ref={forwardedRef} className={popupContainerClassName}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: apiKey,
            language: i18n.language,
            libraries: ['geometry'],
          }}
          options={(maps: Maps): GoogleMapReact.MapOptions => ({
            fullscreenControl: false,
            zoomControlOptions: {
              position: maps.ControlPosition.RIGHT_TOP,
            },
            ...(rest.options && rest.options),
          })}
          {...rest}
          defaultCenter={CENTER_FALLBACK}
          defaultZoom={ZOOM_FALLBACK}
          center={center}
          zoom={zoom}
          onGoogleApiLoaded={({ map, maps }): void => {
            mapInstanceRef.current = map;
            mapApisRef.current = maps;
            addTopRightControlToMap({ map, maps });

            if (onGoogleApisInitialized) {
              onGoogleApisInitialized({ mapInstance: map, mapApis: maps });
            }
          }}
          onDrag={onDrag}
          onZoomAnimationStart={onZoomAnimationStart}
          yesIWantToUseGoogleMapApiInternals
        >
          {children}
        </GoogleMapReact>
      </div>
      {isLoading && (
        <div className="map__loading-overlay">
          <Spin size="large" />
        </div>
      )}
    </StyledMap>
  );
};

const Map = React.forwardRef<HTMLDivElement, MapProps>((props, ref) => (
  <MapComponent {...props} forwardedRef={ref}>
    {props.children}
  </MapComponent>
));

Map.displayName = 'Map';
export { Map };
