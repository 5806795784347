export const LANGUAGES_USER_SNAP_SUPPORTED: Record<string, string> = {
  en: 'en',
  cs: 'cs',
  de: 'de',
  es: 'es',
  fr: 'fr',
  hr: 'hr',
  hu: 'hu',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  lt: 'lt',
  nl: 'nl',
  pl: 'pl',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sv: 'sv',
  tr: 'tr',
  pt: 'pt',
  zh: 'zh-CN',
};
