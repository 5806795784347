import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VncModalSelectors } from '../../state/vncModalSelectors';
import { StyledVncStatusModal } from './VncStatusModal.styles';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';

export interface VncStatusModalProps {
  handleHideModal: () => void;
}

export const VncStatusModal = ({ handleHideModal }: VncStatusModalProps): JSX.Element => {
  const { t } = useTranslation();
  const isModalVisible = !!useSelector(VncModalSelectors.selectVncModalStatusIsVisible);

  return (
    <StyledVncStatusModal
      title={t('vnc.modals.status.title')}
      open={isModalVisible}
      className="vnc-status-modal"
      closable={false}
      width={550}
      destroyOnClose
      footer={[
        <SecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleHideModal}>
          {t('common.cancel')}
        </SecondaryButton>,
      ]}
    >
      <p className="vnc-status-modal__description">{t('vnc.modals.status.description.generalFailedDescription')}</p>
    </StyledVncStatusModal>
  );
};
