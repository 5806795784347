import {
  AppConfigDataClient,
  GetLatestConfigurationCommand,
  StartConfigurationSessionCommand,
} from '@aws-sdk/client-appconfigdata';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { singleton } from 'tsyringe';
import isNil from 'lodash-es/isNil';
import {
  AwsFeatureFlagUserGroup,
  ConfigurationSetFeatureMap,
  Feature,
  IFeatureFlagConfig,
  initialFeatureFlagState,
} from '../../../config/feature-flags';
import { Optional } from '../../../lib/types/Optional';
import { isEnvVarEnabled } from '../../../lib/utils/env-variables/isEnvVarEnabled';
import { AuthenticationService } from '../authentication/AuthenticationService';
import { BrowserStorage } from '../storage/BrowserStorage';
import { AwsAppConfigData, AwsAppConfigFeatureFlags, ConfigurationSet } from './interfaces/FeatureFlag.types';
import { Nullable } from 'lib/types/Nullable';

@singleton()
export class FeatureFlagService {
  private static readonly CONFIG_STORAGE_KEY = 'feature-flag-config';

  private currentConfig: IFeatureFlagConfig = initialFeatureFlagState;

  private readonly startConfigurationSessionsCommand: StartConfigurationSessionCommand;
  private readonly awsRegion: Optional<string>;
  private readonly amUserPoolId: Optional<string>;
  private readonly amIdentityPoolId: Optional<string>;
  private readonly applicationIdentifier: Optional<string>;
  private readonly environmentIdentifier: Optional<string>;
  private readonly configurationProfileIdentifier: Optional<string>;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly browserStorage: BrowserStorage
  ) {
    this.awsRegion = process.env.REACT_APP_AWS_REGION;
    this.amUserPoolId = process.env.REACT_APP_AWS_USER_POOL_ID;
    this.amIdentityPoolId = process.env.REACT_APP_AWS_AM_IDENTITY_POOL_ID;
    this.applicationIdentifier = process.env.REACT_APP_AWS_APPCONFIG_APPLICATION_ID;
    this.environmentIdentifier = process.env.REACT_APP_AWS_APPCONFIG_ENVIRONMENT_ID;
    this.configurationProfileIdentifier = process.env.REACT_APP_AWS_APPCONFIG_CONFIG_PROFILE_ID;

    this.startConfigurationSessionsCommand = new StartConfigurationSessionCommand({
      ApplicationIdentifier: this.applicationIdentifier,
      EnvironmentIdentifier: this.environmentIdentifier,
      ConfigurationProfileIdentifier: this.configurationProfileIdentifier,
    });
  }

  public isFeatureEnabled(feature: Feature): boolean {
    return this.currentConfig[feature];
  }

  public async getEffectiveFeatureFlagConfig(): Promise<IFeatureFlagConfig> {
    let config: IFeatureFlagConfig = {
      ...initialFeatureFlagState,
    };

    ConfigurationSetFeatureMap[ConfigurationSet.STABLE].forEach(feature => {
      config[feature] = true;
    });

    let awsAppConfigData: Nullable<AwsAppConfigData> = null;

    try {
      awsAppConfigData = await this.getLatestAppConfigConfiguration();
    } catch (error) {
      console.error(error);
    }

    const nextEmVersionFeaturesEnabled =
      awsAppConfigData?.[AwsAppConfigFeatureFlags.ENABLE_NEXT_EM_VERSION_FEATURES]?.enabled;
    const roboticsFeaturesEnabled = awsAppConfigData?.[AwsAppConfigFeatureFlags.ENABLE_ROBOTICS_FEATURES]?.enabled;
    const userGroups = await this.authenticationService.getUserGroups();

    if (nextEmVersionFeaturesEnabled || userGroups.includes(AwsFeatureFlagUserGroup.NEXT_EM_VERSION_FEATURE_ACCESS)) {
      ConfigurationSetFeatureMap[ConfigurationSet.NEXT_EM_VERSION_FEATURES].forEach(feature => {
        config[feature] = true;
      });
    }

    if (roboticsFeaturesEnabled || userGroups.includes(AwsFeatureFlagUserGroup.ROBOTICS_FEATURE_ACCESS)) {
      ConfigurationSetFeatureMap[ConfigurationSet.ROBOTICS_FEATURES].forEach(feature => {
        config[feature] = true;
      });
    }

    if (isEnvVarEnabled(process.env.REACT_APP_ENABLE_ALL_FEATURES)) {
      config = Object.values(Feature).reduce<IFeatureFlagConfig>(
        (accumulator, feature) => ({
          ...accumulator,
          [feature]: true,
        }),
        {} as IFeatureFlagConfig
      );
    }

    this.currentConfig = config;

    return config;
  }

  public persistFeatureFlagConfig(config: IFeatureFlagConfig): void {
    try {
      this.currentConfig = config;
      const serializedConfig = JSON.stringify(config);

      this.browserStorage.set(FeatureFlagService.CONFIG_STORAGE_KEY, serializedConfig, {
        obfuscate: true,
      });
    } catch (error) {
      console.error(error);
    }
  }

  public restoreFeatureFlagConfig(): Nullable<IFeatureFlagConfig> {
    try {
      const serializedConfig = this.browserStorage.get(FeatureFlagService.CONFIG_STORAGE_KEY, {
        deobfuscate: true,
      });

      if (!serializedConfig) return null;

      const config = JSON.parse(serializedConfig);

      this.currentConfig = config;

      return config;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async getLatestAppConfigConfiguration(): Promise<Nullable<AwsAppConfigData>> {
    const idToken = (await this.authenticationService.getTokens())?.jwt.idToken;

    if (!idToken) return null;

    if (
      isNil(this.awsRegion) ||
      isNil(this.amUserPoolId) ||
      isNil(this.amIdentityPoolId) ||
      isNil(this.applicationIdentifier) ||
      isNil(this.environmentIdentifier) ||
      isNil(this.configurationProfileIdentifier)
    ) {
      return null;
    }

    const appConfigDataClient = new AppConfigDataClient({
      region: this.awsRegion,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({
          region: this.awsRegion,
        }),
        identityPoolId: this.amIdentityPoolId,
        logins: {
          [`cognito-idp.${this.awsRegion}.amazonaws.com/${this.amUserPoolId}`]: idToken,
        },
      }),
    });

    const { InitialConfigurationToken: initialConfigurationToken } = await appConfigDataClient.send(
      this.startConfigurationSessionsCommand
    );

    const getLatestConfigurationCommand = new GetLatestConfigurationCommand({
      ConfigurationToken: initialConfigurationToken,
    });

    const getLatestConfigurationCommandOutput = await appConfigDataClient.send(getLatestConfigurationCommand);

    const config = JSON.parse(
      new TextDecoder('utf-8').decode(getLatestConfigurationCommandOutput.Configuration)
    ) as Record<string, any>;

    return config as AwsAppConfigData;
  }
}
