import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IAnalyticsProductInfo } from '../interfaces/Analytics.types';
import { AnalyticsActions } from '../state/analyticsSlice';

export type UseAnalyticsSetProductInfoOptions = IAnalyticsProductInfo;

/**
 * Hook to set product information for analytics
 */
export function useAnalyticsSetProductInfo({
  productName,
  productMaterialNo,
  productSerialNo,
}: UseAnalyticsSetProductInfoOptions): void {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      AnalyticsActions.setProductInfo({
        productName,
        productMaterialNo,
        productSerialNo,
      })
    );

    return () => {
      dispatch(
        AnalyticsActions.setProductInfo({
          productName: null,
          productMaterialNo: null,
          productSerialNo: null,
        })
      );
    };
  }, [dispatch, productMaterialNo, productName, productSerialNo]);
}
