import { Optional } from '../../../../lib/types/Optional';
import { IAuthenticationTokens } from '../interfaces/IAuthenticationTokens';

export function isAuthenticationTokenObject(
  maybeTokens: Optional<IAuthenticationTokens>
): maybeTokens is IAuthenticationTokens {
  if (maybeTokens === null) return false;
  if (maybeTokens === undefined) return false;

  if (
    Object.prototype.hasOwnProperty.call(maybeTokens?.decoded, 'idToken') &&
    typeof maybeTokens.decoded.idToken === 'object' &&
    Object.prototype.hasOwnProperty.call(maybeTokens?.decoded, 'accessToken') &&
    typeof maybeTokens.decoded.accessToken === 'object' &&
    Object.prototype.hasOwnProperty.call(maybeTokens?.jwt, 'idToken') &&
    Object.prototype.hasOwnProperty.call(maybeTokens?.jwt, 'accessToken') &&
    Object.prototype.hasOwnProperty.call(maybeTokens?.jwt, 'refreshToken')
  ) {
    return true;
  }

  return false;
}
