import { Store } from 'redux';
import { container, InjectionToken, instanceCachingFactory } from 'tsyringe';
import { registerDependencies } from '../../../config/dependency-injection';
import { ICreateReduxStoreOptions } from '../state-management/interfaces/StateManagement.types';
import { StateManagement } from '../state-management/StateManagement';

export class DependencyInjection {
  public static registerDependencies = (): void => {
    registerDependencies();
  };

  // We need the other dependencies already instantiated when creating the store
  // so we do it in a separate step
  public static registerStore = (reduxStoreOptions: ICreateReduxStoreOptions): void => {
    container.register('Store', {
      useFactory: instanceCachingFactory<Store>(() => StateManagement.createReduxStore(reduxStoreOptions)),
    });
  };

  public static resolve = <T>(token: InjectionToken<T>): T => container.resolve(token);
}
